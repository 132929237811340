import {merge} from "lodash";
import partnerMessages from "@i18n_messages_en-US"

import components from "./components"
import views from "./views"
import db from "./db";
import navbar from "./navbar";
import footer from "./footer";
import navigation from "./navigation";
import breadcrumbs from "./breadcrumbs";
import orders from "./orders";
import widget from "./widget";
import quiz from "./quiz";
import modal from "./modal";
import errors from "./errors";
import products from "./products";
import checkout_config from "./checkout_config";
import approval_config from "./approval_config";
import catalog from "./catalog";

const result = {
    components,
    db,
    navbar,
    footer,
    views,
    navigation,
    breadcrumbs,
    orders,
    widget,
    quiz,
    modal,
    errors,
    products,
    checkout_config,
    approval_config,
    catalog,

    networkIssuesHeader: "Issues connecting to the Gear services",
    networkIssues: "Desculpe, estamos tendo problemas para conectar aos serviços do Gear. Verifique se você está desconectado da VPN antes de continuar."
};

export default merge(result, partnerMessages)