import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";

export default {
    namespaced: true,
    state: {
        type: null
    },
    getters: {
        type: state => JSON.parse(JSON.stringify(state.type))
    },
    mutations: {
        setType: (state, type) => state.type = type,
    },
    actions: {
        fetch(context) {

            const userId = this.getters["user/id"];

            return retryRequest(() => platform.get(`/api/v2/user/${userId}/constraints`))
                .then(response => context.commit("setType", response.data.results.constraints.type));
        },
        clear(context) {

            context.commit("setType", null);
        }
    }
}