export default {

    title: "Add New Address",

    model: {
        country: {
            label: "Country"
        }
    },

    field: {
        default: {
            name: {
                label: {Name: "Name"},
                placeholder: {Name: "Name"}
            },
            personalEmail: {
                label: {"Personal Email": "Personal Email"},
                placeholder: {"Personal Email": "Personal Email"}
            },
            managerEmail: {
                label: {"Manager Email": "Manager's Email"},
                placeholder: {"Manager Email": "Manager's Email"}
            },
            managerName: {
                label: {"Manager Name": "Manager's Name"},
                placeholder: {"Manager Name": "Manager's Name"}
            },
            postalCode: {
                label: {Zipcode: "Zipcode"},
                placeholder: {Zipcode: "Zipcode"}
            },
            phoneNumber: {
                label: {"Phone Number": "Phone Number"},
                placeholder: {"Phone Number": "Phone Number"}
            },
            firstStreet: {
                label: {"Street": "Street"},
                placeholder: {"Street": "Street"}
            },
            secondStreet: {
                label: {"Street": "Street"},
                placeholder: {"Street": "Street"}
            },
            city: {
                label: {"City": "City"},
                placeholder: {"City": "City"}
            },
            identifier: {
                label: {"identifier": "Identifier"},
                placeholder: {"identifier": "Identifier"}
            },
            state: {
                label: {"State": "State",},
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District Of Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "North Carolina",
                    "North Dakota": "North Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "South Carolina",
                    "South Dakota": "South Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            deliveryInstructions: {
                label: {"Delivery Instructions": "Delivery Instructions",},
                placeholder: {"Extra address information, delivery instructions, etc": "Extra address information, delivery instructions, etc.",}
            }
        },
        BRA: {
            name: {
                label: {"Full name": "Full Name"},
                placeholder: {"Full name": "Full Name"}
            },
            firstStreet: {
                label: {"Address 1": "Address 1"},
                placeholder: {"Address 1": "Street + house/apt number"}
            },
            secondStreet: {
                label: {"Address 2": "Address 2"},
                placeholder: {"Address 2": "Street + house/apt number"}
            },
            city: {
                label: {City: "City"},
                placeholder: {City: "City"},
            },
            state: {
                label: {State: "State"},
                placeholder: {State: "State"}
            },
            postalCode: {
                label: {"Zip code": "Zip code"},
                placeholder: {"Zip code": "Zip code"}
            },
            identifier: {
                label: {CPF: "CPF"},
                placeholder: {CPF: "CPF"}
            }
        }
    },

    errors: {
        required: "{field} is required.",
        mask: "{field} is invalid."
    },

    buttons: {
        submit: {
            text: "Save",
            saving: "Saving"
        },
        cancel: {
            text: "Cancel"
        }
    },

    disclaimer: "Adding this address will require email verification. After saving the address, please check your email and click the link within.",

    addressAdded: "Address has been added",
}