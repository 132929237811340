<template>

    <form @submit.prevent="base_submit('model', 'submit', null)">

        <div class="row">

            <!-- Text Content -->
            <div class="col-12">
                <h3 v-if="content.title" class="pb-3"
                    :class="{'text-center':'center'==content.title.align,'text-left':'left'==content.title.align}">
                    {{content.title.text}}
                </h3>

                <p v-if="content.description"
                   :class="{'text-center':'center'==content.description.align,'text-left':'left'==content.description.align}"
                   v-html="content.description.text">
                </p>

                <p v-if="content.text"
                   :class="{'text-center':'center'==content.text.align,'text-left':'left'==content.text.align}"
                   v-html="content.text.text"></p>

                <p v-if="content.info"
                   :class="{'text-center':'center'==content.info.align,'text-left':'left'==content.info.align}">
                    <i>{{content.info.text}}</i>
                </p>
            </div>

            <!-- Components -->
            <div class="col-12 pt-3" v-for="(component, index) in components" :key="index"
                 :class="[component.wrapperCssClass]">

                <div v-if="quiz.types.dropdown==component.type">
                    <div v-if="!component.decorator">
                        <form-select v-model="model[component.name]"
                                     :validator="v$.model[component.name]"
                                     :label="component.label"
                                     :options="component.options"
                                     :errors="component.errors"
                                     :disabled="disabled||component.disabled"/>
                    </div>
                    <div v-else class="d-flex">
                        <div v-if="component.decorator.left" class="flex-grow-0">
                            <img v-if="'image' === component.decorator.left.type"
                                 alt=""
                                 :height="component.decorator.left.height"
                                 :width="component.decorator.left.width"
                                 :class="component.decorator.left.class"
                                 :src="component.decorator.left.url"/>
                        </div>
                        <div class="flex-grow-1">
                            <form-select v-model="model[component.name]" class="form-group "
                                         :validator="v$.model[component.name]"
                                         :label="component.label"
                                         :options="component.options"
                                         :errors="component.errors"
                                         :disabled="disabled||component.disabled"/>
                        </div>
                        <div v-if="component.decorator.right" class="flex-grow-0">
                            <img v-if="'image' === component.decorator.right.type"
                                 alt=""
                                 :height="component.decorator.right.height"
                                 :width="component.decorator.right.width"
                                 :class="component.decorator.right.class"
                                 :src="component.decorator.right.url"/>
                        </div>
                    </div>
                </div>

                <div v-if="quiz.types.textbox==component.type">
                    <form-textbox v-model="model[component.name]"
                                  :validator="v$.model[component.name]"
                                  :label="component.label"
                                  :placeholder="component.placeholder"
                                  :errors="component.errors"
                                  :max-characters="component.maxCharacters"
                                  :disabled="disabled||component.disabled"></form-textbox>
                </div>

                <div v-if="quiz.types.textarea==component.type">
                    <form-textarea v-model="model[component.name]"
                                   :validator="v$.model[component.name]"
                                   :label="component.label"
                                   :placeholder="component.placeholder"
                                   :errors="component.errors"
                                   :max-characters="component.maxCharacters"
                                   :disabled="disabled||component.disabled"></form-textarea>
                </div>

                <div v-if="quiz.types.collection==component.type">
                    <collection class="row" v-model="model[component.name]"
                                :validator="v$.model[component.name]"
                                :error-class="'col-12'"
                                :option="component.option"
                                :errors="component.errors"
                                :selection-limit="component.selectionLimit">
                        <div class="text-center" v-for="(option, optionIndex) in component.options"
                             :key="optionIndex"
                             :class="[component.cell.class]">

                            <collection-item :model-value="option.value" :disabled="disabled">

                                <collection-item-image :url="option.image"></collection-item-image>
                                <collection-item-title :title="option.title"></collection-item-title>
                                <collection-item-description
                                    :description="option.description"></collection-item-description>

                            </collection-item>

                        </div>
                    </collection>
                </div>

            </div>

            <!-- Buttons -->
            <div class="col-12 pt-3">

                <p v-if="content.afterComponents"
                   :class="{'text-center':'center'==content.afterComponents.align,'text-left':'left'==content.afterComponents.align}"
                   v-html="content.afterComponents.text"></p>

                <div class="form-group">

                    <div class="row">
                        <div class="col-6 text-right" v-if="displayBackButton">

                            <button type="button" class="btn btn-link pr-4 text-uppercase"
                                    :disabled="disabled"
                                    @click="back">
                                <span class="icon-left-open"></span>
                                {{backButtonText}}
                            </button>
                        </div>
                        <div :class="{'col-12 text-center':!displayBackButton,'col-6':displayBackButton}">

                            <button type="submit" class="btn btn-primary" :disabled="disabled"
                                    :class="{'pl-4':!disabled, 'pr-4':isLastPage}">
                                <spinner v-if="disabled"></spinner>
                                {{nextButtonText}}<span v-if="!isLastPage&&!disabled" class="icon-right-open"></span>
                            </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </form>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import debounce from "lodash/debounce"

    import mixinQuizPage from "@/mixins/quiz/page.mjs";
    import mixinQuizValidation from "@/mixins/quiz/validation";

    import FormSelect from "@/components/form/FormSelect";
    import Collection from "@/components/quiz/Collection";
    import CollectionItem from "@/components/quiz/CollectionItem";
    import CollectionItemImage from "@/components/quiz/CollectionItemImage";
    import CollectionItemDescription from "@/components/quiz/CollectionItemDescription";
    import CollectionItemTitle from "@/components/quiz/CollectionItemTitle";
    import FormTextbox from "@/components/form/FormTextbox";
    import FormTextarea from "@/components/form/FormTextarea";
    import Spinner from "@/components/Spinner";

    export default {
        name: "QuizPage",
        mixins: [mixinQuizPage, mixinQuizValidation],
        emits: ["update:modelValue", "title"],
        setup() {

            return {
                v$: useVuelidate()
            }
        },
        props: {
            modelValue: {
                type: Object,
                required: true
            },
            quizPage: {
                type: Object,
                required: true
            },
            quiz: {
                type: Object,
                required: true
            },
            components: {
                type: Array,
                required: true
            },
            disabled: Boolean,
            isLastPage: Boolean,
            displayBackButton: Boolean,
            nextButtonText: String,
            backButtonText: String
        },
        components: {
            Spinner,
            FormTextarea,
            FormTextbox,
            CollectionItemTitle,
            CollectionItemDescription,
            CollectionItemImage,
            CollectionItem,
            Collection,
            FormSelect
        },
        data() {

            return {
                model: {},
                skipModelWatcher: false
            }
        },
        computed: {
            content() {

                return this.quiz_page_getPageContent(this.quiz, this.quizPage);
            }
        },
        validations() {

            return this.quizPage && this.quizPage.components
                ? {model: this.quiz_validation_createValidationRules(this.quiz, this.quizPage.components)}
                : {model: {}};
        },
        methods: {
            back() {

                this.$emit("back");
            },
            submit() {

                this.$emit("next");
            },
            update: debounce(function (self, model) {

                self.$emit("update:modelValue", model);
            }, 500),
        },
        watch: {
            content: {
                immediate: true,
                deep: true,
                handler(content) {

                    if (content.title) {

                        this.$emit("title", content.title.text);
                    }
                }
            },
            model: {
                deep: true,
                handler(model) {

                    this.update(this, model)
                }
            },
            modelValue: {
                deep: true,
                immediate: true,
                handler(model) {

                    if (model) {

                        Object.keys(model).forEach(key => this.model[key] = model[key]);
                    }
                }
            }
        }
    }
</script>