import ES6Promise from 'es6-promise/auto'
import {createApp} from 'vue'
import App from './App.vue'
import platform from "@/api/platform";
import order from "@/api/order";
import delivery from "@/api/delivery";
import storage from "@/api/storage";
import router from '@/router'
import store from '@/store'
import {setupI18n} from "@/i18n/i18n";
import plugin_maska from '@/plugins/maska'

import mixins_base from "@/mixins/base";
import mixins_breadcrumbs from "@/mixins/breadcrumbs";

import directive_dropdown from "@/directives/dropdown";

import plugin_toast from "./plugins/toast";
import plugin_modal from "./plugins/modal";
import plugin_broadcast from "./plugins/broadcast";
import plugin_activity from "./plugins/activity";

import {errorHandler} from "./errors";

import Loading from "@/components/Loading";
import Spinner from "@/components/Spinner";
import Modals from "@/components/Modals";

const i18n = setupI18n();

const app = createApp(App)
    .use(store)
    .use(router)
    .use(platform, {
        baseURL: process.env.VUE_APP_PLATFORM_API_URL,
        params: {
            partner_id: process.env.VUE_APP_PARTNER_ID,
        }
    })
    .use(order, {
        baseURL: process.env.VUE_APP_ORDER_API_URL,
        params: {
            partner_id: process.env.VUE_APP_PARTNER_ID,
        }
    })
    .use(delivery, {
        baseURL: process.env.VUE_APP_DELIVERY_API_URL,
        params: {
            partner_id: process.env.VUE_APP_PARTNER_ID,
        }
    })
    .use(storage, {
        storage: process.env.VUE_APP_STORAGE,
        namespace: process.env.VUE_APP_STORAGE_NAMESPACE,
    })
    .use(i18n)
    .use(plugin_toast)
    .use(plugin_modal, {store})
    .use(plugin_maska)
    .use(plugin_broadcast)
    .use(plugin_activity)

    .mixin(mixins_base)
    .mixin(mixins_breadcrumbs)

    .component('loading', Loading)
    .component('spinner', Spinner)
    .component('modals', Modals)

    .directive('dropdown', directive_dropdown);

app.config.errorHandler = errorHandler;

app.mount('#app');