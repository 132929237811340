export default {
    title: "Delivery Confirmation",
    header: "@:views.survey.delivery.title",
    message: "Did you receive any of the items below? Check any that you did receive.",
    button: {
        submit: {
            cta: "Submit",
            processing: "Submitting"
        }
    }
}