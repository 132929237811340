import debounce from "lodash/debounce"
import mixinProducts from "@/mixins/products";

export default {
    mixins: [mixinProducts],
    data() {

        return {
            widget_listener: null,
            widgetListenerInterval: null,
            widget_options: {}
        }
    },
    computed: {
        widget_optionsJson() {

            return JSON.stringify({
                anchorMargin: "60px 15px 0 0",
                token: this.$store.getters["user/token"]
            });
        },
        widget_displayWidgetButton() {

            return -1 === ["helpdesk"].indexOf(this.$route.name);
        }
    },
    methods: {
        widget_init() {

            const callback = this.widget_getCallback();
            const token = this.$store.getters["user/token"];
            const addresses = this.widget_options.addresses
                ? this.widget_options.addresses
                : (token ? this.$store.getters["user/address/items"] : []);
            const callbackAddressTFA = addressId => this.$store.dispatch("user/address/confirm", addressId);
            const products = (this.widget_options.orders ? this.widget_options.orders : (token ? this.orders : [])).reduce((result, order) => {

                // Filter products by status
                order.products.forEach(product => {

                    if (-1 === ["canceled", "returned"].indexOf(product.status)) {

                        const index = result.findIndex(p => p.sku == product.sku);

                        if (-1 === index) {

                            result.push({
                                ...product,
                                name: this.products_translateProductName(product),
                                description: this.products_translateProductDescription(product),
                                note: this.products_translateProductNote(product),
                                quantity: product.quantity ? product.quantity : 1
                            });
                        } else {

                            result[index].quantity += product.quantity ? product.quantity : 1;
                        }
                    }
                });

                return result;
            }, []);

            return callback({
                token,
                addresses,
                callbackAddressTFA,
                products,
                email: this.$store.getters["user/profile/email"],
                name: this.$store.getters["user/profile/name"],
                country: this.$store.getters["user/profile/country"],
                language: this.$store.getters["i18n/language"].value,
                target_user: this.widget_options.target_user
            });
        },
        widget_refresh: debounce(function () {

            const callback = this.widget_getCallback();

            if ("function" !== typeof callback && !this.widget_listener) {

                // Wait for widget callback is ready for passing data
                this.widget_listener = setInterval(() => {

                    if ("function" === typeof this.widget_getCallback()) {

                        // Remove existing listener
                        clearInterval(this.widget_listener);
                        this.widget_init();
                    }
                }, 50);
            }

            if ("function" === typeof callback) {

                this.widget_init();
            }
        }, 500),
        widget_getCallback() {

            return window.__SupportWidgetListener;
        }
    }
}