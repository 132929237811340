import useVuelidate from '@vuelidate/core';
import get from 'lodash/get'
import set from 'lodash/set'

export default {
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {

        return {
            t_path: null,
            processing: false,
            validated: false,
            loaded: false,
        }
    },
    methods: {
        t(key, locale, values) {

            if (this.t_path) {

                return this.$t(`${this.t_path}.${key}`, locale, values);
            }

            return this.$t(key, locale, values);
        },
        te(key, locale) {

            if (this.t_path) {

                return this.$te(`${this.t_path}.${key}`, locale);
            }

            return this.$te(key, locale);
        },
        tc(key, choice, locale, values) {

            if (this.t_path) {

                return this.$tc(`${this.t_path}.${key}`, choice, locale, values);
            }

            return this.$tc(key, choice, locale, values);
        },
        base_submit(path = 'model', callback = 'submit', processingPath = 'processing') {

            let processing;

            if (processingPath) {

                processing = get(this, processingPath);
            }

            if (!processing && this.base_validate(path)) {

                set(this, processingPath, true);

                this[callback]();
            }
        },
        base_validate(path = '') {

            if ('boolean' === typeof this.validated) {

                this.validated = true;
            }

            if (path && get(this.v$, `${path}`)) {

                get(this.v$, `${path}`).$touch();
            } else {

                this.v$.$touch();
            }

            return !get(this.v$, `${path ? path + '.' : ''}$invalid`);
        },
        base_error(error, redirect) {

            if (redirect && error.response && error.response.status) {

                return this.$router.push({
                    name: "errors.code",
                    params: {
                        code: error.response.status
                    },
                    query: {
                        message: get(error, "response.data.results.0.msg")
                    }
                })
            }

            this.$toast.error(error);
        }
    }
}