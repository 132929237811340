<template>
    <nav class="navbar navbar-expand-md">

        <button class="navbar-toggler" v-if="displayUserMenu" type="button" @click="display.userMenu=!display.userMenu">
            <span class="navbar-toggler-icon"></span>
        </button>

        <router-link class="navbar-brand" :to="{name:'home'}"></router-link>

        <ul class="navbar-nav ml-md-auto d-md-none flex-row" v-if="0<cartItems.length">
            <li class="nav-item pr-2">
                <router-link class="nav-link navbar-link-cart" :to="{name:'order.submit'}">
                    <span class="icon-basket"></span>
                    <span class="badge badge-pill badge-danger">{{cartItems.length}}</span>
                </router-link>
            </li>
        </ul>

        <div class="collapse navbar-collapse" v-if="displayUserMenu" :class="{show:display.userMenu}">
            <ul class="navbar-nav navbar-nav-bordered ml-md-auto">
                <li v-if="1 < languages.length" class="nav-item nav-item__simple d-none d-md-block dropdown">
                    <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenuDesktop>
                        {{language.name}}
                        <span class="icon-down-dir"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ref="languageDropdownMenuDesktop">
                        <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                           :key="lang.value"
                           :class="{active:lang.value==language.value}"
                           @click.prevent="setLanguage(lang)">
                            {{lang.name}}
                        </a>
                    </div>
                </li>
                <li v-if="1 < languages.length" class="nav-item nav-item__simple d-md-none">
                    <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenu>
                        {{language.name}}
                        <span class="icon-down-dir"></span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right dropdown-menu-collapsed" ref="languageDropdownMenu">
                        <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                           :key="lang.value"
                           :class="{active:lang.value==language.value}"
                           @click.prevent="setLanguage(lang)">
                            {{lang.name}}
                        </a>
                    </div>
                </li>
                <li v-if="widget_displayWidgetButton" class="nav-item nav-item__simple d-block pr-2">
                    <a href="#" class="nav-link" role="button" :data-widget-trigger="widget_optionsJson">
                        {{t('support')}}
                    </a>
                </li>
                <li class="nav-item d-none d-md-block pr-2" v-if="0<cartItems.length">
                    <router-link class="nav-link navbar-link-cart" :to="{name:'order.submit'}">
                        <span class="icon-basket"></span>
                        <span class="badge badge-pill badge-danger"
                              v-if="0<cartItems.length">{{cartItems.length}}</span>
                    </router-link>
                </li>
                <li class="nav-item d-none d-md-block dropdown">
                    <a href="#" class="nav-link" role="button" v-dropdown.desktopUserMenu>
                        <span class="icon-user"></span>
                        {{userName}}
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" ref="desktopUserMenu">
                        <router-link v-if="enableDisplayEditProfile" class="dropdown-item" :to="{name:'quiz', query: {reset: true}}">
                            {{t('userMenu.editProfile')}}
                        </router-link>
                        <router-link class="dropdown-item" :to="{name:'auth.logout'}">
                            {{t('userMenu.logout')}}
                        </router-link>
                    </div>
                </li>
                <li class="nav-item d-md-none">
                    <router-link v-if="enableDisplayEditProfile" class="nav-link" :to="{name:'quiz', query: {reset: true}}">
                        {{t('userMenu.editProfile')}}
                    </router-link>
                    <router-link class="nav-link" :to="{name:'auth.logout'}">{{t('userMenu.logout')}}</router-link>
                </li>
            </ul>
        </div>
        <ul v-else-if="1 < languages.length" class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
                <a href="#" class="nav-link" role="button" v-dropdown.languageDropdownMenu>
                    {{language.name}}
                    <span class="icon-down-dir"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right position-absolute" ref="languageDropdownMenu">
                    <a href="#" role="button" class="dropdown-item" v-for="lang in languages"
                       :key="lang.value"
                       :class="{active:lang.value==language.value}"
                       @click.prevent="setLanguage(lang)">
                        {{lang.name}}
                    </a>
                </div>
            </li>
        </ul>

    </nav>
</template>

<script>

    import {mapGetters} from "vuex"

    import mixinWidget from "@/mixins/widget";
    import mixinView from "@/mixins/view";

    import {USER_MENU_DISPLAY_EDIT_PROFILE} from "@/constants";

    export default {
        name: "PageNavbar",
        mixins: [mixinWidget, mixinView],
        data() {

            return {
                t_path: "navbar",

                display: {
                    userMenu: false
                },

                enableDisplayEditProfile: USER_MENU_DISPLAY_EDIT_PROFILE
            }
        },
        computed: {
            ...mapGetters({
                isAuthenticated: "user/isAuthenticated",
                cartItems: "user/cart/items",
                userName: "user/profile/firstName",
                languages: "i18n/languages",
                language: "i18n/language",
            }),
            displayUserMenu() {

                return this.isAuthenticated;
            },
            currentRouteName() {

                return this.$route && this.$route.name ? this.$route.name.replace(/\./, "-") : null;
            }
        },
        methods: {
            setLanguage(language) {

                this.$i18n.locale = language.value;

                this.$store.dispatch("i18n/setLanguage", language);
                this.$store.dispatch("user/setLanguage", language.value);

                this.view_setTitle();
            }
        },
        watch: {
            $route(to) {

                this.display.userMenu = false;
            }
        }
    }
</script>