export const AUTOLOGIN_ENABLED = 1 == process.env.VUE_APP_ENABLED_AUTOLOGIN;

export const PERMISSIONS = {
    EMPLOYEE: "order",
    MANAGER: "approval",
    HELPDESK: "helpdesk"
};

export const CATALOG_STATUS_MESSAGE = 1 == process.env.VUE_APP_CATALOG_STATUS_MESSAGE;

export const ADDRESS_TFA = 1 == process.env.VUA_APP_ADDRESS_TFA;

export const USER_MENU_DISPLAY_EDIT_PROFILE = 1 == process.env.VUE_APP_USER_MENU_DISPLAY_EDIT_PROFILE;