<template>
    <div>
        <div v-if="result.header">
            <page-message>
                <template #header>{{result.header}}</template>
                <template #message>{{result.message}}</template>
                <template #cta>
                    <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
                </template>
            </page-message>
        </div>
        <page-message v-else>
            <template #header>{{t('message')}}</template>
            <template #html>
                <!-- TODO: product list here -->
            </template>
            <template #cta>
                <button type="button" class="btn btn-primary mr-3" :disabled="processing.yes||processing.no"
                        @click="submit('yes')">
                    <spinner v-if="processing.yes"/>
                    {{t('cta.yes')}}
                </button>
                <button type="button" class="btn btn-primary" :disabled="processing.yes||processing.no"
                        @click="submit('no')">
                    <spinner v-if="processing.no"/>
                    {{t('cta.no')}}
                </button>
            </template>
        </page-message>
    </div>

</template>

<script>

    import mixinView from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsSurveysDelivery",
        mixins: [mixinView],
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.surveys.delivery",

                order_id: null,
                reference_ids: [],
                result: {
                    header: null,
                    message: null
                },
                processing: {
                    yes: false,
                    no: false
                }
            }
        },
        methods: {
            handle(code, results, status) {

                if ("no" === status && "200_delivery" == code) {

                    return this.$router.push({
                        name: "survey.delivery",
                        query: {
                            order_id: results.order_id,
                            reference_ids: this.$route.query.reference_ids,
                            items: JSON.stringify(results.items.map(i => ({
                                name: i.name,
                                id: i._id,
                                pictures: i.pictures,
                                reference_id: i.reference_id,
                                generic_name: i.generic_name ? i.generic_name : i.name
                            })))
                        }
                    });
                }

                if ("yes" === status && "204_delivery" == code) {

                    return this.$router.push({
                        name: "survey.customer",
                        query: {
                            order_id: results.order_id,
                            reference_ids: this.$route.query.reference_ids,
                            items: JSON.stringify(results.items.map(i => ({
                                id: i._id,
                                pictures: i.pictures,
                                reference_id: i.reference_id,
                                generic_name: i.generic_name ? i.generic_name : i.name
                            })))
                        }
                    });
                }

                const path = `views.tunnel.service.responses.${code}`;

                this.view_setTitle(this.$t(`${path}.title`));

                this.result.header = this.$t(`${path}.header`);
                this.result.message = this.$t(`${path}.message`);
            },
            submit(status) {

                this.processing[status] = true;

                this.$delivery
                    .get("/emailconfirm", {params: {...this.$route.query, status}})
                    .then(response => this.handle(response.data.meta.code, response.data.results, status))
                    .finally(() => this.processing[status] = false);
            },
            fetch() {

                this.order_id = this.$route.query.order_id;
                this.reference_ids = this.$route.query.reference_ids;
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>

<style lang="scss">

</style>