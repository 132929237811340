import ViewsApproval from "@/views/approval/ViewsApproval";
import {PERMISSIONS} from "@/constants";


export default {
    routes: [{
        path: "/approvals",
        name: "approvals",
        component: ViewsApproval,
        meta: {
            requireAuth: true,
            permissions: [PERMISSIONS.MANAGER]
        }
    }]
}