<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #message>{{t('message')}}</template>
    </page-message>
</template>

<script>

    import mixinView from "@/mixins/view";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrors500",
        components: {
            PageMessage
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.errors.500"
            }
        },
    }
</script>