<template>
    <div class="container">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8">
                <panel class="mt-3">
                    <div class="h4 text-center text-uppercase font-weight-bold pt-3 pb-4">
                        {{t('header')}}
                    </div>
                    <form class="survey-customer-form" @submit.prevent="base_submit('model', 'submit')">

                        <form-item-selector v-model="model.service_rating"
                                            :label="t('model.service_rating.label')"
                                            :disabled="processing"
                                            :validator="v$.model.service_rating"
                                            :options="votes"></form-item-selector>

                        <form-textarea v-model="model.service_likes"
                                       :label="t('model.service_likes.label')"
                                       :disabled="processing"
                                       :placeholder="t('model.service_likes.placeholder')"
                                       :max-characters="1000"></form-textarea>

                        <form-textarea v-model="model.service_improvements"
                                       :label="t('model.service_improvements.label')"
                                       :disabled="processing"
                                       :placeholder="t('model.service_improvements.placeholder')"
                                       :max-characters="1000"></form-textarea>

                        <form-item-selector v-model="model.service_easy_to_follow"
                                            :label="t('model.service_easy_to_follow.label')"
                                            :disabled="processing"
                                            :validator="v$.model.service_easy_to_follow"
                                            :options="votes"></form-item-selector>

                        <div v-for="(item, index) in model.items" :key="item.id">

                            <form-item-selector v-model="model.items[index].setup_rating"
                                                :label="t('model.item.setup_rating.label', {name: item.generic_name})"
                                                :disabled="processing"
                                                :validator="v$.model.items[index].setup_rating"
                                                :options="votes"></form-item-selector>

                            <form-textarea v-model="model.items[index].comments"
                                           :label="t('model.item.comments.label', {name: item.generic_name})"
                                           :disabled="processing"
                                           :placeholder="t('model.item.comments.placeholder', {name: item.generic_name})"
                                           :max-characters="1000"></form-textarea>

                        </div>

                        <div class="row">
                            <div class="col-12 offset-md-1 col-md-10">
                                <div class="form-group text-center">
                                    {{t('disclaimer')}}
                                </div>
                            </div>
                        </div>


                        <div class="form-group text-center pt-2">
                            <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                <spinner v-if="processing"></spinner>
                                {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                            </button>
                        </div>
                    </form>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {required} from "@vuelidate/validators"
    import mixinView from "@/mixins/view";
    import {retryRequest} from "@/helpers/request";
    import Panel from "@/components/Panel";
    import FormTextarea from "@/components/form/FormTextarea";
    import FormItemSelector from "@/components/form/FormItemSelector";

    export default {
        name: "ViewsSurveyCustomer",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        components: {FormItemSelector, FormTextarea, Panel},
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.survey.customer",

                votes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => ({value: i, name: i})),

                model: {
                    order_id: null,
                    reference_ids: null,
                    service_rating: null,
                    service_likes: null,
                    service_improvements: null,
                    service_easy_to_follow: null,
                    items: []
                }
            }
        },
        validations() {

            const model = {
                service_rating: {
                    required,
                },
                service_easy_to_follow: {
                    required,
                },
                items: {}
            };

            this.model.items.forEach((item, index) => {


                model.items[index] = {
                    setup_rating: {
                        required
                    }
                }
            });

            return {
                model,
            }
        },
        methods: {
            submit() {

                this.processing = true;

                return retryRequest(() => this.$delivery.post("/survey", this.model))
                    .then(() => this.$router.push({name: "delivery.submitted"}))
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            },
            fetch() {

                this.model.order_id = this.$route.query.order_id;
                this.model.reference_ids = [this.$route.query.reference_ids];

                const items = JSON.parse(this.$route.query.items);

                this.model.items = items.map(item => ({
                    id: item.id,
                    generic_name: item.generic_name,
                    comments: null,
                    setup_rating: null
                }))
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>