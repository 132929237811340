<template>
    <div class="orders-history-order-product">

        <div class="info">
            <!-- Product name and details (quantity, note)-->
            <div class="name">
                {{genericName}}
                <div v-if="product.quantity && 1 < product.quantity" class="details">
                    <small>{{t('quantity')}}: {{product.quantity}}</small>
                </div>

            </div>
            <!-- Product status-->
            <div class="status text-right">
                <div class="name">{{displayStatus}}</div>
                <!-- Cancel -->
                <div v-if="isCancelAllowed" class="control">
                    <button type="button" class="btn btn-link btn-sm btn-control p-0"
                            :disabled="processing.cancel"
                            @click="cancel(product)">
                        <span v-if="processing.cancel" class="icon-spinner animate-spin"></span>
                        <span v-html="t('cancel')"></span>
                    </button>
                </div>
                <!-- Request Cancellation-->
                <div v-if="isRequestCancellationAllowed" class="control">
                    <button type="button" class="btn btn-link btn-sm btn-control p-0"
                            :disabled="processing.cancel"
                            @click="display.cancel=!display.cancel">
                        <span v-if="processing.cancel" class="icon-spinner animate-spin"></span>
                        <span v-html="t('cancel')"></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="small text-right" v-if="notApprovedReason">
            {{t('reason')}}: {{notApprovedReason}}
        </div>

        <!-- Request to cancel -->
        <form class="request-cancel" v-if="display.cancel" @submit.prevent="submitRequestCancellation(product._id)">

            <form-textarea v-model="model.cancel.reason"
                           :label="t('model.cancel.reason.label')"
                           :validator="v$.model.cancel.reason"
                           :placeholder="t('model.cancel.reason.placeholder')"
                           :disabled="processing.cancel"/>

            <div class="row">
                <div class="col-6" :class="{'col-6':!processing.cancel,'col-12':processing.cancel}">
                    <button type="submit" class="btn btn-sm btn-primary w-100" :disabled="processing.cancel">
                        <spinner v-if="processing.cancel"></spinner>
                        {{processing.cancel ? t('buttons.cancel.submit.processing') : t('buttons.cancel.submit.cta')}}
                    </button>
                </div>
                <div v-if="!processing.cancel" class="col-6">
                    <button type="button" class="btn btn-sm btn-outline-primary w-100"
                            :disabled="processing.cancel"
                            @click="display.cancel=false">
                        {{t('buttons.cancel.cancel.cta')}}
                    </button>
                </div>
            </div>

        </form>
        <!-- Request to cancle success message -->
        <div v-if="success.cancel">
            <div class="small">
                {{t('requestToCancelInfo')}}
            </div>
            <div class="text-center">
                <button type="button" class="btn btn-sm btn-outline-primary" @click="success.cancel = false">
                    {{t('cancelOk')}}
                </button>
            </div>
        </div>

        <!-- Tracking -->
        <div v-if="product.tracking" class="tracking">
            {{t('tracking')}}:
            <span v-if="product.trackingUrl">
                    <a :href="product.trackingUrl" target="_blank" rel="noopener">{{product.tracking}}</a>
                </span>
            <span v-else>{{product.tracking}}</span>
        </div>

        <!-- Status -->
        <div v-if="estimatedDelivery" class="status small text-right">
            {{estimatedDelivery}}
        </div>
    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";

    import mixinProducts from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import FormTextarea from "@/components/form/FormTextarea";
    import Spinner from "@/components/Spinner";

    const ALLOWED_TO_CANCEL_STATUSES = [
        "submitted",
        "confirmed",
        "pending_approval"
    ];

    const ALLOWED_TO_REQUEST_CANCELLATION_STATUSES = [
        "sent",
        "acknowledged",
        "processed"
    ];

    const HIDDEN_STATUSES = [
        "delivery_not_received",
        "delivery_email"
    ];

    const HIDDEN_STATUSES_FALLBACK = HIDDEN_STATUSES.concat([
        "delivered",
        "delivery_received"
    ]);

    export default {
        name: "OrdersHistoryOrderProduct",
        mixins: [mixinProducts],
        emits: ["refresh"],
        components: {
            Spinner,
            FormTextarea
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.orders.history.order",

                display: {
                    details: false,
                    cancel: false
                },

                processing: {
                    cancel: false
                },

                success: {
                    cancel: false
                },

                model: {
                    cancel: {
                        reason: null
                    }
                }
            }
        },
        computed: {
            estimatedDelivery() {

                return this.product.estimated_ship_date && this.product.estimated_ship_date.message
                    ? getTranslationValue(this.product.estimated_ship_date, ["message"], this.$i18n)
                    : null
            },
            isCancelAllowed() {

                return -1 !== ALLOWED_TO_CANCEL_STATUSES.indexOf(this.product.status);
            },
            displayStatus() {

                // Some statuses are technical and should not be displayed as is
                if (-1 !== HIDDEN_STATUSES.indexOf(this.product.status) && this.product.statusHistory) {

                    // Go through history and pick 1st approved status
                    for (let i = 0; i < this.product.statusHistory.length; i++) {

                        if (-1 === HIDDEN_STATUSES_FALLBACK.indexOf(this.product.statusHistory[i].status)) {

                            return this.$t(`orders.status.${this.product.statusHistory[i].status}`);
                        }
                    }
                } else {

                    return this.$t(`orders.status.${this.product.status}`);
                }

                return this.$t("orders.status.confirmed");
            },
            isRequestCancellationAllowed() {

                return -1 !== ALLOWED_TO_REQUEST_CANCELLATION_STATUSES.indexOf(this.product.status);
            },
            genericName() {

                return this.products_translateProductName(this.product);
            },
            note() {

                return this.products_translateProductNote(this.product);
            },
            notApprovedReason() {

                return this.product.approval_reason;
            }
        },
        validations() {

            return {
                model: {
                    cancel: {
                        reason: {}
                    }
                }
            }
        },
        methods: {
            fetch() {

                this.display.details = true === this.expand;
            },
            cancel(product) {

                this.processing.cancel = true;

                this.$store
                    .dispatch("user/orders/cancel", {id: product._id, sendEmail: true})
                    .then(() => {

                        this.$toast.success(this.t("orderCancelled"));
                        this.$emit("refresh")
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.cancel = false);
            },
            submitRequestCancellation(productId) {

                this.processing.cancel = true;

                this.$platform
                    .post(
                        "/api/v2/widget/stylerequest",
                        {
                            category: "Order Questions",
                            details: this.model.cancel.reason,
                            products: [productId],
                            issue: "Request to Cancel"
                        },
                        {
                            params: {
                                app_id: process.env.VUE_APP_ID,
                                app_secret: process.env.VUE_APP_SECRET
                            }
                        }
                    )
                    .then(() => {

                        this.success.cancel = true;
                        this.display.cancel = false;
                        this.model.cancel.reason = null;
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.cancel = false)
            }
        },
    }
</script>