<template>
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-8 order-md-1">

                <loading v-if="loading"/>
                <div v-else>

                    <views-home-type-products v-if="type === types.products"
                                              :grouping="grouping"
                                              :groupings="groupings"
                                              :config="config"
                                              :catalog-message="catalogMessage"/>

                    <views-home-type-categories v-if="type === types.categories"
                                                :grouping="grouping"
                                                :groupings="groupings"
                                                :catalog-message="catalogMessage"/>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-4 order-md-0">
                <panel>
                    <orders-history></orders-history>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinView from "@/mixins/view";
    import mixinConstraints from "@/mixins/constraints";
    import mixinWidget from "@/mixins/widget";

    import {retryRequest} from "@/helpers/request";

    import Loading from "@/components/Loading";
    import ProductsList from "@/components/products/ProductsList";
    import AdvisorRecommendations from "@/components/advisor/AdvisorRecommendations";
    import Panel from "@/components/Panel";
    import OrdersHistory from "@/components/orders/OrdersHistory";
    import ViewsHomeTypeProducts from "@/views/home/ViewsHomeTypeProducts";
    import ViewsHomeTypeCategories from "@/views/home/ViewsHomeTypeCategories";
    import {CATALOG_STATUS_MESSAGE} from "@/constants";

    const GROUPING = process.env.VUE_APP_HOME_PAGE_GROUPING;
    const TYPE = process.env.VUE_APP_HOME_PAGE_TYPE;

    export default {
        name: "ViewsHome",
        mixins: [mixinView, mixinConstraints, mixinWidget],
        components: {
            ViewsHomeTypeCategories,
            ViewsHomeTypeProducts,
            AdvisorRecommendations,
            ProductsList,
            Loading,
            Panel,
            OrdersHistory
        },
        data() {

            return {
                t_path: "views.home",

                loading: false,
                grouping: GROUPING,
                types: {
                    products: "products",
                    categories: "categories"
                },
                type: TYPE,
                config: {
                    default: {
                        max_items: null,
                        item_wrap: true
                    }
                },
                groupings: [],
                catalogMessageSource: null
            }
        },
        computed: {
            catalogMessage() {

                return this.catalogMessageSource
                    ? this.$t(`catalog.message.${this.catalogMessageSource.message}`, {
                        equipmentQuizLink: this.$router.resolve({name: "quiz", query: {reset: true}}).fullPath,
                        widgetOptions: this.widget_optionsJson,
                        supportEmail: this.catalogMessageSource.email
                    })
                    : null
            }
        },
        methods: {
            handleConfig(data) {

                this.config.default.max_items = data.config.max_items;
                this.config.default.item_wrap = data.config.item_wrap;
            },
            handleCatalog(result) {

                this.groupings = result.groupings;

                if (0 === this.groupings.length) {

                    this.$activity.log("catalog_no_groupings", {constraints: this.$store.getters["user/constraints/type"]});
                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.$activity.log("catalog_groupings", {
                    groupings: this.groupings.map(grouping => ({name: grouping.name, id: grouping.id})),
                    constraints: this.$store.getters["user/constraints/type"]
                });
            },
            fetch() {

                if (!this.constraints_hasUserAvailableProducts()) {

                    this.$activity.log("catalog_no_products", {constraints: this.$store.getters["user/constraints/type"]});

                    return false;
                }

                this.loading = true;

                const promises = [
                    retryRequest(() => this.$platform.get(`/api/v2/hp_config`)),
                    retryRequest(() => this.$platform.get(`/api/v2/groupingList/${this.grouping}`))
                ];

                if (CATALOG_STATUS_MESSAGE) {

                    promises.push(this.$store.dispatch("user/catalog/getCatalogMessage"))
                }

                return Promise
                    .all(promises)
                    .then(responses => {

                        const [config, catalog, catalogMessage] = responses;

                        this.handleConfig(config.data.results);
                        this.handleCatalog(catalog.data.results);

                        this.catalogMessageSource = catalogMessage && catalogMessage.message
                            ? catalogMessage
                            : null;
                    })
                    .finally(() => this.loading = false);
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>