<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #message>
            <div>

                <div v-for="note in notes" class="pb-3 text-center" :key="note">{{note}}</div>

                <div v-if="0 === outOfStockProducts.length">
                    <div v-html="t('message', {url: $router.resolve({name:'home'}).fullPath})"></div>
                </div>
                <div v-else>

                    <div v-html="t('messageOutOfStock', {url: $router.resolve({name:'home'}).fullPath})"></div>

                    <!-- Product items -->
                    <div class="row pt-5">
                        <div class="offset-md-2 offset-lg-3 col-md-8 col-lg-6">
                            <div class="row pt-3" v-for="product in outOfStockProducts" :key="product.sku">
                                <div class="col-4">
                                    <products-product-thumbnails v-if="0 < product.pictures.length" size="small"
                                                                 :value="product.pictures"/>
                                </div>
                                <div class="col-8 text-left">
                                    {{product.name}}
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>

        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import mixinView from "@/mixins/view";
    import mixinCart from "@/mixins/cart";
    import mixinProducts from "@/mixins/products";

    import PageMessage from "@/components/page/PageMessage";
    import ProductsProductThumbnails from "@/components/products/product/ProductsProductThumbnails";

    export default {
        name: "ViewsOrderSubmitted",
        components: {
            ProductsProductThumbnails,
            PageMessage
        },
        mixins: [mixinView, mixinCart, mixinProducts],
        data() {

            return {
                t_path: "views.order.submitted",
                outOfStock: [],
                notes: []
            }
        },
        computed: {
            outOfStockProducts() {

                return this.outOfStock.map(item => ({
                    ...item,
                    name: this.products_translateProductName(item)
                }));
            }
        },
        mounted() {

            if (this.$route.query.outOfStockSkus) {

                this.outOfStock = this.$route.query.outOfStockSkus
                    .split(",")
                    .map(sku => {

                        // Find item for showing in modal
                        const item = this.cart_find({sku});

                        return item && item.item ? item.item : null;
                    })
                    .filter(item => !!item);
            }

            if (this.$route.query.notes) {

                this.notes = JSON.parse(this.$route.query.notes);
            }

            // Clear cart
            this.$store.dispatch("user/cart/clear");
        }
    }
</script>