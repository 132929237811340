import {order} from "@/api/order";
import {retryRequest} from "@/helpers/request";

export default {
    namespaced: true,
    state: {
        items: [],
    },
    getters: {
        items: state => state.items
    },
    mutations: {
        setItems: (state, items) => state.items = items,
        addItem: (state, item) => state.items.push(item),
    },
    actions: {
        fetch(context, params) {

            const userId = this.getters["user/id"];

            return retryRequest(() => order.get(`/users/${userId}/orders`, {params}))
                .then(response => {

                    context.commit("setItems", response.data.results);

                    return response.data.results;
                })
        },
        post(context, model) {

            return retryRequest(
                () => order.post(`/users/${this.getters["user/id"]}/orders`, model),
                [1000, 2000],
                error => !error.response || 409 != error.response.status || 400 != error.response.status
            );
        },
        cancel(context, model) {

            return retryRequest(
                () => order.put(`/users/${this.getters["user/id"]}/items/${model.id}/cancel`, model),
                [1000, 2000]
            );
        },
    }
}