<template>
    <div class="container">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8">
                <panel class="mt-3">
                    <div class="h4 text-center text-uppercase font-weight-bold  pt-3 pb-2">
                        {{t('header')}}
                    </div>
                    <div class="text-center pb-4">
                        {{t('message')}}
                    </div>
                    <form @submit.prevent="submit">

                        <div class="form-group" v-for="item in items" :key="item._id">
                            <div class="row">
                                <div class="col-4 align-self-center text-center">
                                    <form-checkbox :selected-value="item.reference_id"
                                                   :disabled="processing"
                                                   v-model="model.checked"></form-checkbox>
                                </div>
                                <div class="col-4">
                                    <img class="img-fluid survey-delivery-item-img"
                                         :src="item.pictures[0].medium" alt="">
                                </div>
                                <div class="col-4 align-self-center">
                                    <div class="survey-delivery-item-name">
                                        {{item.generic_name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group text-center pt-2">
                            <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                <spinner v-if="processing"></spinner>
                                {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                            </button>
                        </div>
                    </form>
                </panel>
            </div>
        </div>
    </div>
</template>

<script>

    import mixinView from "@/mixins/view";
    import Panel from "@/components/Panel";
    import FormCheckbox from "@/components/form/FormCheckbox";
    import {retryRequest} from "@/helpers/request";

    export default {
        name: "ViewsSurveyDelivery",
        components: {
            FormCheckbox,
            Panel
        },
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.survey.delivery",

                items: [],

                model: {
                    checked: []
                }
            }
        },
        methods: {
            submit() {

                this.processing = true;

                const request = {
                    order_id: this.$route.query.order_id,
                    delivery_received: this.model.checked,
                    delivery_not_received: this.items.filter(item => -1 === this.model.checked.indexOf(item.reference_id)).map(i => i.reference_id)
                };

                return retryRequest(() => this.$delivery.post("/confirm", request))
                    .then(() => this.$router.push({name: "delivery.submitted"}))
                    .catch(error => {

                        if (!error.response) {

                            return this.$toast.error(this.t("errors.requestError"), {
                                timeout: 5000
                            });
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.processing = false);
            },
            fetch() {

                this.items = JSON.parse(this.$route.query.items);
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>