import confirm from "./confirm";
import orderSubmitConfirm from "./orderSubmitConfirm";
import orderOutOfStock from "./orderOutOfStock";
import quizProfile from "./quizProfile";

export default {
    confirm,
    orderSubmitConfirm,
    quizProfile,
    orderOutOfStock,
}