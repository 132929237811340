<template>
    <div class="orders-history">
        <div class="font-weight-bold pb-3">{{t('title')}}</div>

        <div v-if="error">
            {{t('error')}}
        </div>
        <div v-else-if="0 === orders.length">
            {{t('noOrders')}}
        </div>

        <div v-for="(order, index) in orders" :key="order._id" class="orders-history-item">
            <orders-history-order :value="order" :expand="0==index" @refresh="fetch"></orders-history-order>
        </div>

        <div class="text-center pt-3 small" v-html="t('help.text', {widgetOptions:widget_optionsJson})"></div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"
    import widget from "@/mixins/widget";
    import OrdersHistoryOrder from "./OrdersHistoryOrder";

    export default {
        name: "OrdersHistory",
        mixins: [widget],
        components: {
            OrdersHistoryOrder
        },
        data() {

            return {
                t_path: "components.orders.history",

                email: process.env.VUE_APP_SUPPORT_EMAIL,
                error: false
            }
        },
        computed: {
            ...mapGetters({
                orders: "user/orders/items"
            })
        },
        methods: {
            fetch() {

                const request = {
                    skip: 0,
                    limit: 10
                };

                return this.$store.dispatch("user/orders/fetch", request).catch((error) => {

                    this.error = true;

                    this.$toast.error(error)
                });
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>