<template>
    <div class="container">
        <div class="row" v-if="treeLoading&&loading">
            <div class="col-12">
                <loading/>
            </div>
        </div>
        <div class="row" v-else>
            <div v-if="0 < treeItems.length" class="d-none d-md-block col-md-4 col-lg-4">
                <panel>
                    <tree-view :items="treeItems" :value="treeActive.id" @select="navigate"/>
                </panel>
            </div>
            <div class="col-12 col-md-8 col-lg-8">
                <loading v-if="loading"/>
                <div v-else-if="product">
                    <products-product-page :value="product" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";
    import mixin_view from "@/mixins/view";
    import mixin_products from "@/mixins/products";

    import {retryRequest} from "@/helpers/request";

    import ProductsProductPage from "@/components/products/product/ProductsProductPage";
    import TreeView from "@/components/TreeView";
    import Panel from "@/components/Panel";

    export default {
        name: "ViewsProductsProduct",
        mixins: [mixin_view, mixin_products],
        components: {
            Panel,
            TreeView,
            ProductsProductPage
        },
        data() {

            return {
                t_path: "views.products.product",
                grouping: this.$route.params.grouping,
                loading: false,
                product: null
            }
        },
        computed: {
            ...mapGetters({
                treeItems: "tree/items",
                treeActive: "tree/active",
                treeLoading: "tree/loading"
            })
        },
        methods: {
            navigate(id) {

                this.$router.push({
                    name: "products",
                    params: {
                        grouping: this.grouping,
                        grouping_id: id
                    }
                });
            },
            fetch() {

                this.loading = true;

                return this.$store
                    .dispatch("tree/fetch", {
                        grouping: this.grouping,
                        active: this.$route.params.grouping_id
                    })
                    .then(() => retryRequest(() => this.$platform.get(`/api/v2/grouping/product/${this.$route.params.sku}`)))
                    .then(response => this.product = response.data.results[0])
                    .finally(() => this.loading = false);
            },
            refresh() {

                this.products_updateBreadcrumbsProduct(this.product);
                this.view_setTitle(this.products_translateProductName(this.product));
            }
        },
        watch: {
            "$i18n.locale": function () {

                this.refresh();
            },
            product() {

                this.refresh();
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>