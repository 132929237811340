<template>
    <div>
        <div class="page-message">
            <div class="container">
                <div class="row">
                    <div class="col-10 offset-1 col-lg-6 offset-lg-3">
                        <div class="content">
                            <h1 class="header">
                                <slot name="header"></slot>
                            </h1>
                            <div class="message">
                                <slot name="html"></slot>
                                <p>
                                    <slot name="message"></slot>
                                </p>
                                <p class="text-left">
                                    <slot name="messageLeftAligned"></slot>
                                </p>
                            </div>
                            <div class="cta">
                                <slot name="cta"></slot>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


</template>

<script>
    export default {
        name: "PageMessage"
    }
</script>