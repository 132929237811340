<template>
    <div v-if="loading" class="container">
        <div class="row">
            <div class="col-12">
                <loading></loading>
            </div>
        </div>
    </div>
    <page-message v-else-if="error">
        <template #header>{{t('error')}}</template>
        <template #message>{{error}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <page-message v-else-if="noOrders">
        <template #header>{{t('noOrders')}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <form v-else-if="0 < model.orders.length" @submit.prevent="base_submit('model', 'submit', null)">
        <div class="container approvals">
            <div class="row">
                <div class="col-12">
                    <div class="approvals-container">
                        <table class="table small">
                            <thead>
                            <tr>
                                <th>{{t('orders.name')}}</th>
                                <th>{{t('orders.date')}}</th>
                                <th>{{t('orders.details')}}</th>
                                <th v-for="(name, nameIndex) in editableApprovalConfigNames"
                                    :key="'approval_config_name_'+nameIndex">
                                    {{name}}
                                </th>
                                <th colspan="2">{{t('orders.approval')}}</th>
                            </tr>
                            </thead>

                            <tbody v-for="(order, orderIndex) in model.orders" :key="order.id">
                            <tr>
                                <td :rowspan="order.rowspan">
                                    {{order.name}}
                                </td>
                                <td>
                                    <div>{{$d(order.date, 'date')}}</div>
                                    <div v-for="(approvalField, approvalFieldIndex) in notEditableApprovalConfig"
                                         class="d-flex small pt-1"
                                         :key="model.orders[orderIndex].orderId">
                                        <div class="pr-3">
                                            {{$t('approval_config.' + approvalField.payload.field + '.' + approvalField.label)}}:
                                        </div>
                                        <div class="pre-white-space">
                                            {{model.orders[orderIndex][approvalField.payload.field]}}
                                        </div>
                                    </div>
                                    <!-- Approval reasons -->
                                    <div v-if="order.priorApprovals.loaded" class="pt-1">
                                        <a v-if="0 < model.orders[orderIndex].priorApprovals.items.length"
                                           role="button" class="small d-flex"
                                           @click.prevent="model.orders[orderIndex].priorApprovals.show=!model.orders[orderIndex].priorApprovals.show">
                                            <span v-html="t('priorApprovals')"></span>
                                            <span>
                                            <span v-if="!model.orders[orderIndex].priorApprovals.show"
                                                  class="ml-1 icon-down-dir"></span>
                                            <span v-else class="ml-1 icon-up-dir"></span>
                                        </span>
                                        </a>
                                        <span v-else class="small text-dark">
                                        {{t('noPriorApprovals')}}
                                    </span>
                                    </div>
                                    <a v-else role="button" class="small d-flex pt-1"
                                       @click.prevent="loadPriorApprovals(order)">
                                        <span v-html="t('priorApprovals')"></span>
                                        <span>
                                        <span v-if="order.priorApprovals.loading"
                                              class="ml-1 animate-spin icon-spinner"></span>
                                        <span v-else class="ml-1 icon-down-dir"></span>
                                    </span>
                                    </a>
                                </td>
                                <td>
                                    {{translateDetails(order)}}
                                </td>
                                <td v-for="field in editableApprovalConfig">
                                    <form-textbox v-if="'textbox'===field.type"
                                                  textbox-class="form-control form-control-sm"
                                                  v-model="model.orders[orderIndex][field.payload.field]"
                                                  :validator="v$.model.orders[orderIndex][field.payload.field]"
                                                  :placeholder="field.placeholder"
                                                  :disabled="processing"
                                                  :mask="field.mask"
                                                  :errors="field.errors"/>
                                </td>
                                <td>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input"
                                                   v-model="model.orders[orderIndex].approved"
                                                   :value="true">
                                            {{t('model.approve.yes.label')}}
                                        </label>
                                    </div>
                                    <div class="form-check">
                                        <label class="form-check-label">
                                            <input type="radio" class="form-check-input"
                                                   v-model="model.orders[orderIndex].approved"
                                                   :value="false">
                                            {{t('model.approve.no.label')}}
                                        </label>
                                    </div>
                                </td>
                                <td>
                                    <form-textbox textbox-class="form-control form-control-sm"
                                                  v-model="model.orders[orderIndex].reason"
                                                  :validator="v$.model.orders[orderIndex].reason"
                                                  :placeholder="t('model.reason.placeholder')"
                                                  :disabled="processing"/>
                                </td>
                            </tr>
                            <tr v-for="(priorApproval, priorApprovalIndex) in model.orders[orderIndex].priorApprovals.items"
                                v-show="model.orders[orderIndex].priorApprovals.show">
                                <td class="bg-light">{{$d(priorApproval.date, 'date')}}</td>
                                <td class="bg-light">
                                    <div v-if="priorApproval.approved">{{t('approved')}}</div>
                                    <div v-else>{{t('notApproved')}}</div>
                                </td>
                                <td class="bg-light">{{translateDetails(priorApproval)}}</td>
                                <td class="bg-light" v-for="(field, fieldIndex) in editableApprovalConfig"
                                    :key="'prior_approval_' + fieldIndex">
                                    {{priorApproval[field.payload.field]}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group text-center">
                        {{t('review')}}
                    </div>
                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-sm btn-primary"
                                :disabled="0 === handled.length || processing">
                            <spinner v-if="processing"></spinner>
                            {{processing ? t('buttons.submit.processing') : t('buttons.submit.text')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <page-message v-else>
        <template #header>{{t('noApprovals')}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import get from "lodash/get";
    import mixinValidator from "@/helpers/validator";
    import {requiredIf} from "@vuelidate/validators"
    import mixinView from "@/mixins/view";
    import PageMessage from "@/components/page/PageMessage";
    import FormTextbox from "@/components/form/FormTextbox";
    import Spinner from "@/components/Spinner";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    export default {
        name: "ViewsApproval",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixinView, mixinValidator],
        components: {
            Spinner,
            FormTextbox,
            PageMessage
        },
        data() {

            return {
                t_path: "views.approval",
                processing: false,
                error: null,
                loading: true,
                noOrders: false,
                model: {
                    orders: []
                }
            }
        },
        computed: {
            approvalConfig() {

                return this.$store.getters["user/approval/config"].map(field => ({
                    ...field,
                    ...["label", "placeholder"].reduce((result, property) => ({
                        ...result,
                        [property]: this.$te(`approval_config.${field.payload.field}.${property}`)
                            ? this.$t(`approval_config.${field.payload.field}.${property}`)
                            : null
                    })),
                    errors: Object
                        .keys(field.validation ? field.validation : {})
                        .reduce((errors, key) => {

                            if (this.$te(`approval_config.${field.payload.field}.errors.${key}`)) {

                                errors[key] = this.$t(`approval_config.${field.payload.field}.errors.${key}`);
                            }

                            return errors;
                        }, {})
                }))
            },
            editableApprovalConfig() {

                return this.approvalConfig.filter(item => true === item.editable);
            },
            notEditableApprovalConfig() {

                return this.approvalConfig.filter(item => !item.editable);
            },
            editableApprovalConfigNames() {

                return this.editableApprovalConfig.map(field => ["label", "placeholder"].reduce((result, prop) => {

                    if (result) {

                        return result;
                    }

                    return this.$te(`approval_config.${field.payload.field}.${prop}`)
                        ? this.$t(`approval_config.${field.payload.field}.${prop}`)
                        : null
                }, null));
            },
            handled() {

                return this.model.orders.filter(order => null !== order.approved);
            }
        },
        validations() {

            return {
                model: {
                    orders: Object
                        .keys(this.model.orders)
                        .reduce((result, index) => {

                            return {
                                ...result,
                                [index]: {
                                    reason: {
                                        required: requiredIf(() => false === this.model.orders[index].approved)
                                    },
                                    ...this.editableApprovalConfig.reduce((approvalConfigResult, config) => {

                                        approvalConfigResult[config.payload.field] = this.validator_createDynamicValidationRules(
                                            config.validation,
                                            {}
                                        );

                                        approvalConfigResult[config.payload.field] = this.validator_applyDynamicMask(
                                            config.mask,
                                            approvalConfigResult[config.payload.field]
                                        )

                                        return approvalConfigResult;
                                    }, {}),
                                }
                            };
                        }, {})
                }
            }
        },
        methods: {
            translateDetails(item) {

                return getTranslationValue(item, "details", this.$i18n);
            },
            submitOrders() {

                this.processing = true;

                const request = this.handled.reduce((result, order) => ({
                    ...result,
                    [order.orderId]: {
                        approved: order.approved,
                        reason: order.reason,
                        ...this.editableApprovalConfig.reduce((result, field) => ({
                            ...result,
                            [field.payload.field]: order[field.payload.field]
                        }), {})
                    }
                }), {});

                this.$order
                    .post(`/users/${this.$store.getters["user/id"]}/approvals`, request)
                    .then(() => this.$store.dispatch("user/surveys/fetch"))
                    .then(() => {
                        this.$modal.message(this.t("saved"));
                        this.fetch();
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false)
            },
            submit() {

                const orders = this.handled;

                if (this.model.orders.length != orders.length) {

                    return this.$modal.confirm(this.t("confirm")).then(() => this.submitOrders());
                }

                return this.submitOrders();
            },
            loadPriorApprovals(order) {

                order.priorApprovals.loading = true;

                return this.$order
                    .get(`/users/${order.userId}/prior-approvals`)
                    .then(response => {

                        order.priorApprovals.items = Object.keys(response.data.results.data).map(orderId => ({
                            name: response.data.results.data[orderId].name,
                            date: response.data.results.data[orderId].date,
                            details: response.data.results.data[orderId].details,
                            lang_overrides: response.data.results.data[orderId].lang_overrides,
                            approved: response.data.results.data[orderId].approved,
                            ...this.approvalConfig.reduce((result, field) => ({
                                ...result,
                                [field.payload.field]: response.data.results.data[orderId][field.payload.field]
                            }), {}),
                        }));

                        order.priorApprovals.show = true;
                        order.priorApprovals.loaded = true;
                        order.rowspan = 2 + order.priorApprovals.items.length;

                    })
                    .catch(error => this.base_error(error))
                    .finally(() => order.priorApprovals.loading = false)

            },
            fetch() {

                this.loading = true;

                this.$order
                    .get(`/users/${this.$store.getters["user/id"]}/approvals`)
                    .then(response => {

                        const orders = response.data.results.data;

                        if (0 === orders.length) {

                            this.noOrders = true;

                            return true;
                        }

                        this.model.orders = Object
                            .keys(orders)
                            .map(orderId => ({
                                orderId,
                                userId: orders[orderId].user_id,
                                name: orders[orderId].name,
                                date: new Date(orders[orderId].date),
                                details: orders[orderId].details,
                                lang_overrides: orders[orderId].lang_overrides,
                                approved: null,
                                ...this.approvalConfig.reduce((result, field) => ({
                                    ...result,
                                    [field.payload.field]: orders[orderId][field.payload.field]
                                }), {}),
                                priorApprovals: {
                                    loading: false,
                                    loaded: false,
                                    show: false,
                                    items: []
                                },
                                rowspan: 2
                            }))
                            .sort((a, b) => a.date.getTime() - b.date.getTime());
                    })
                    .catch(error => this.error = error.response && error.response.status
                        ? get(error, "response.data.results.0.msg")
                        : error
                    )
                    .finally(() => this.loading = false)
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>