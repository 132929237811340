export default {
    product: {
        buttons: {
            cart: {
                add: "Add",
                remove: "Remove From Cart"
            }
        },
        description: {
            more: "Click For More"
        },
        added: "Added to Cart"
    }
}