export default {
    cost_center: {
        label: "Cost Center Code",
        placeholder: "Cost Center Code",
        errors: {
            regex: "Invalid cost center code"
        }
    },
    order_note: {
        label: "Note"
    }
}