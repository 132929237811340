<template>
    <div class="container">
        <div class="row" v-if="!treeLoading&&0===treeItems.length">
            <div class="col-12 text-center">
                {{t('noAvailableItems')}}
            </div>
        </div>
        <div class="row" v-else-if="treeLoading&&loading">
            <div class="col-12">
                <loading/>
            </div>
        </div>
        <div class="row" v-else>
            <div class="d-none d-md-block col-md-4">
                <panel>
                    <tree-view :items="treeItems" :value="treeActive.id" @select="navigate" />
                </panel>
            </div>
            <div class="col-12 col-md-8">

                <loading v-if="loading"/>
                <div v-else>
                    <div class="pb-3 pt-3 pt-md-0">
                        <h1>{{title}}</h1>
                        <p v-html="description"></p>
                    </div>

                    <div v-if="0 === products.length">
                        {{t('noAvailableItems')}}
                    </div>

                    <products-list :value="products"
                                   :grouping="grouping"
                                   :grouping-id="treeActive.id" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixinView from "@/mixins/view";
    import mixinConstraints from "@/mixins/constraints";
    import mixin_products from "@/mixins/products";

    import {groupings} from "@/helpers/plp";
    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";
    import {retryRequest} from "@/helpers/request";

    import TreeView from "@/components/TreeView";
    import ProductsList from "@/components/products/ProductsList";
    import Panel from "@/components/Panel";

    export default {
        name: "ViewsProducts",
        mixins: [mixinView, mixinConstraints, mixin_products],
        components: {
            Panel,
            ProductsList,
            TreeView
        },
        data() {

            return {
                t_path: "views.products",
                grouping: this.$route.params.grouping,
                loading: false,
                category: {},
                products: []
            }
        },
        computed: {
            ...mapGetters({
                treeItems: "tree/items",
                treeActive: "tree/active",
                treeLoading: "tree/loading"
            }),
            title() {

                return getTranslationValue(this.category, "title", this.$i18n)
            },
            description() {

                return sanitize(getTranslationValue(this.category, "description", this.$i18n))
            }
        },
        methods: {
            navigate(id) {

                this.$store.dispatch("tree/setActive", id);
            },
            fetch() {

                this.loading = true;

                if (!this.grouping) {

                    // Select 1st grouping by default
                    this.grouping = groupings.recommendation;
                    this.$router.replace({params: {grouping: this.grouping}})
                }

                return this.$store.dispatch("tree/fetch", {
                    grouping: this.grouping,
                    active: this.$route.params.grouping_id
                })
            },
            refresh() {

                this.products_updateBreadcrumbsTree(this.$store.getters["tree/active"]);

                this.view_setTitle(this.title);
            },
            load(groupingId) {

                return retryRequest(() => this.$platform.get(`/api/v2/grouping/${this.grouping}/${groupingId}`))
                    .then(response => {

                        this.category = response.data.results;

                        this.products = groupings.recommendation == this.grouping
                            ? response.data.results.products
                            : this.constraints_filterAvailableProducts(response.data.results.products);
                    })
                    .finally(() => this.loading = false);
            }
        },
        watch: {
            "$i18n.locale": function () {

                this.refresh();
            },
            treeActive(branch) {

                this.$router.replace({params: {grouping_id: branch.id}});

                this.load(branch.id);

                this.refresh();
            },
            category() {

                this.refresh();
            }
        },
        mounted() {

            this.fetch()
        }
    }
</script>