import {platform} from "@/api/platform";
import get from "lodash/get"

export function errorHandler(message, vm, info) {

    const log = {
        source: "mktportal",
        type: "exception",
        details: {
            message: `message: ${get(message, "message", "NOT SET")}\nstack: ${get(message, "stack")}`,
            info,
            url: window.location.href,
            vm_name: get(vm, "$options.name", "NOT SET"),
            vm_props: get(vm, "$props", "NOT SET"),
            referrer: document.referrer
        }
    };

    platform.post("/api/v2/frontend/log", log);

    console.error(message);
    console.error(vm);
    console.error(info);
}