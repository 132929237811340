import mixinValidator from "@/helpers/validator";

const t_path = "components.address.form";

export default {
    mixins: [mixinValidator],
    methods: {
        address_form_getAddressFields(config) {

            return config.map(field => {

                const result = Object.assign({}, field);

                if (field.mask && Array.isArray(field.mask) && 1 === field.mask.length) {

                    field.mask = field.mask[0];
                }

                return result;
            });
        },
        address_form_getAddressFieldTranslation(field, target, country) {

            if ("undefined" === typeof field.display) {

                return null;
            }

            const fieldName = field.field.trim(), fieldTarget = field.display[target].trim().replace(/\.$/, "");
            let path;

            if (country && "string" === typeof country) {

                path = `${t_path}.field.${country}.${fieldName}.${target}.${fieldTarget}`;

                if (this.$te(path)) {

                    return this.$t(path);
                }
            }

            path = `${t_path}.field.default.${fieldName}.${target}.${fieldTarget}`;

            return this.$te(path) ? this.$t(path) : field.display[target];
        },
        address_form_getAddressFieldOptions(field) {

            const options = field.options;

            return options.map(option => {

                const path = `${t_path}.field.default.${field.field}.options.${option.name}`;
                const translation = this.$t(path);

                return {
                    value: option.id,
                    name: translation == path ? option.name : translation
                }
            })
        },
        address_form_createValidationRules(fields) {

            const model = {
                country: {}
            };

            fields.forEach(field => {

                model[field.field] = this.validator_createDynamicValidationRules(field.validation, {});
                model[field.field] = this.validator_applyDynamicMask(field.mask, model[field.field]);
            });

            return {model};
        },
        address_form_getErrorMessages(fields, country) {

            return fields.reduce((errors, field) => {

                return {
                    ...errors,
                    [field.field]: {
                        required: this.$t(`components.address.form.errors.required`, {
                            field: this.address_form_getAddressFieldTranslation(field, "label", country)
                        }),
                        mask: this.$t(`components.address.form.errors.mask`, {
                            field: this.address_form_getAddressFieldTranslation(field, "label", country)
                        })
                    }
                };
            }, {});
        }
    }
}