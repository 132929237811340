export default {
    title: "Order Review",
    header: "@:views.order.submitted.title",

    noItems: "No Selected Products",

    model: {
        addressId: {
            errors: {
                required: "Please fill address"
            }
        },
        product: {
            placeholder: "Please fill amount"
        }
    },

    createNewAddress: "Create New Address",

    button: {
        submit: {
            cta: "Submit Order",
            processing: "Submitting"
        },
        remove: "Remove From Order"
    },

    errors: {
        itemNotExist: "Item «{name}» does not exist in catalog. It will be removed.",
        requestError: "There was an issue submitting your order. Please try submitting again."
    }
}