<template>
    <div>
        <page-message>
            <template #header>{{t('header')}}</template>
            <template #html>
                <form v-show="modes.form === mode" class="text-left" @submit.prevent="base_submit('model', 'submit', null)">
                    <form-textbox v-model="model.email"
                                  :label="t('model.email.label')"
                                  :validator="v$.model.email"
                                  :placeholder="t('model.email.placeholder')"
                                  :disabled="processing"/>

                    <div class="form-group text-center">
                        <button type="submit" class="btn btn-primary" :disabled="disabled">
                            <spinner v-if="processing"></spinner>
                            {{processing ? t('processing') : t('cta')}}
                        </button>
                    </div>
                </form>
                <div v-show="modes.widget === mode">
                    <div id="helpdeskSupportWidget" :data-widget-trigger="widgetOptionsJson"></div>
                </div>
            </template>
        </page-message>
    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {required, email} from "@vuelidate/validators";

    import mixinView from "@/mixins/view";
    import mixinWidget from "@/mixins/widget";
    import PageMessage from "@/components/page/PageMessage";
    import FormTextbox from "@/components/form/FormTextbox";

    export default {
        name: "ViewsHelpdesk",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixinView, mixinWidget],
        components: {
            FormTextbox,
            PageMessage
        },
        data() {

            return {
                t_path: "views.helpdesk",
                processing: false,
                mode: "form",
                modes: {
                    widget: "widget",
                    form: "form"
                },
                model: {
                    email: ""
                }
            }
        },
        computed: {
            widgetOptionsJson() {

                return JSON.stringify({
                    token: this.$store.getters["user/token"],
                    locationSelector: "#helpdeskSupportWidget",
                    embedded: true
                })
            }
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email
                    }
                }
            };
        },
        methods: {
            submit() {

                this.processing = true;

                this.$order
                    .get("/users/orders/helpdesk", {params: {email: this.model.email}})
                    .then(response => {

                        this.widget_options.target_user = this.model.email;
                        this.widget_options.orders = response.data.results;
                        this.widget_options.addresses = [];
                        this.widget_refresh();

                        setTimeout(() => this.mode = this.modes.widget, 500);
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false)
            }
        },
        unmounted() {

            this.widget_options.target_user = null;
            this.widget_options.orders = null;
            this.widget_options.addresses = null;
        }
    }
</script>