<template>
    <div>
        <div class="modals-modal-body pt-4">

            <div class="text-center h5 pb-3">
                {{t('header')}}
            </div>

            <div class="row">
                <div class="col-md-10 offset-md-1">

                    <div class="text-center font-weight-bold pb-3">
                        {{t('message')}}
                    </div>

                    <div class="row pb-3">
                        <div class="offset-2 col-8 offset-md-3 col-md-6">
                            <address-viewer :value="address"></address-viewer>
                        </div>
                    </div>

                    <div v-if="warning && 0 < warning.length" class="pb-3">
                        <i>{{warning}}</i>
                    </div>

                    <div class="text-left pb-3" v-html="t('tip')"></div>

                    <div>
                        <i>{{t('info')}}</i>
                    </div>
                </div>
            </div>

        </div>
        <div class="modals-modal-footer">
            <button type="button" class="btn btn-primary" @click="$emit('save')">
                {{t('buttons.yes')}}
            </button>

            <button type="button" class="btn btn-primary" @click="$emit('close')">
                {{t('buttons.no')}}
            </button>
        </div>

    </div>
</template>

<script>

    import AddressViewer from "@/components/address/AddressViewer";

    const checkNoSpaces = value => "string" === typeof value && -1 === value.indexOf(" ");

    const warningsMap = {
        name: {
            handler: checkNoSpaces,
            key: "name"
        },
        firstStreet: {
            handler: checkNoSpaces,
            key: "street"
        },
        phoneNumber: {
            handler: value => "string" === typeof value && 6 > value.length,
            key: "phone"
        }
    };

    export default {
        name: "ModalOrderSubmitConfirm",
        components: {
            AddressViewer
        },
        props: {
            addressId: {
                type: Number,
                required: true,
            }
        },
        emits: ["save", "close"],
        data() {

            return {
                t_path: "modal.orderSubmitConfirm"
            }
        },
        computed: {
            address() {

                return this.$store.getters["user/address/items"].find(item => item._id == this.addressId)
            },
            warning() {

                const fields = Object.keys(this.address).reduce((result, key) => {

                    if ("undefined" !== typeof warningsMap[key]) {

                        if (warningsMap[key].handler(this.address[key])) {

                            result.push(this.tc(`warning.${warningsMap[key].key}`))
                        }
                    }

                    return result;
                }, []);

                if (0 === fields.length) {

                    return null;
                }

                return this.tc("warning.message", fields.length, {fields: fields.join(", ")})
            }
        }
    }
</script>