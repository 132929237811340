export default {
    types: {
        chair: "Chair",
        desk: "Desk",
        desktop_riser: "Desktop Riser",
        "docking station": "Docking Station",
        hdmi_cable: "HDMI Cable",
        headset: "Headset",
        webcam: "Webcam",
        monitor: "Monitor",
        usb_hub: "Usb Hub",
        keyboard: "Keyboard",
        mouse: "Mouse",
        footrest: "Footrest",
        desktop: "Desktop",
        "laptop stand": "Laptop Stand",
        lamp: "Lamp"
    }
}