export default {
    title: "Orders",
    noOrders: "No Orders",
    error: "There was an issue retrieving orders. Please refresh your browser.",
    order: {
        title: "Order",
        tracking: "Tracking",
        quantity: "Quantity",
        cancel: "Click&nbsp;to&nbsp;Cancel",
        orderCancelled: "Order has been successfully cancelled",
        requestToCancelInfo: "We will contact the supplier to request a cancellation. Please note that this is a request only and cancellation may not be possible. Once we can determine if it can be canceled or not, we will let you know.",
        cancelOk: "Ok",
        reason: "Reason",
        model: {
            cancel: {
                reason: {
                    label: "Reasons for cancellation",
                    placeholder: "Reasons for cancellation"
                }
            }
        },
        buttons: {
            cancel: {
                submit: {
                    cta: "Submit",
                    processing: "Submitting"
                },
                cancel: {
                    cta: "Cancel"
                }
            }
        }
    },
    help: {
        text: "Have questions on your order?<br/>See our <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Support Center</b></a> for help",
        email: {
            subject: ""
        }
    }
}