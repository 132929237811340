export default {
    namespaced: true,
    state: {
        config: []
    },
    getters: {
        config: state => state.config,
        configEditable: state => state.config.filter(item => true === item.editable),
        configStatic: state => state.config.filter(item => !item.editable),
    },
    mutations: {
        setConfig: (state, config) => state.config = config,
    },
    actions: {
        fetch(context) {


            return new Promise(resolve => resolve(
                    context.commit(
                        "setConfig",
                        [
                            {
                                "label": "label",
                                "placeholder": "placeholder",
                                "type": "textbox",
                                "validation": {
                                    "required": true,
                                    "regex": "^[A-Za-z0-9]{4,12}$"
                                },
                                "payload": {
                                    "field": "cost_center"
                                },
                                "mask": [
                                    "XXXX",
                                    "XXXXX",
                                    "XXXXXX",
                                    "XXXXXXX",
                                    "XXXXXXXX",
                                    "XXXXXXXXX",
                                    "XXXXXXXXXX",
                                    "XXXXXXXXXXX",
                                    "XXXXXXXXXXXX"
                                ],
                                "editable": true
                            },
                            {
                                "label": "label",
                                "payload": {
                                    "field": "order_note"
                                }
                            }
                        ]
                    )
                )
            );
        },
        clear(context) {

            context.commit("setConfig", []);
        }
    }
}