<template>
    <div class="cart-review-order" v-if="show">
        <div class="cart-review-order-button">
            <router-link :to="{name:'order.submit'}" class="btn btn-primary">{{t('reviewOrder')}}</router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CartReviewOrder",
        data() {

            return {
                t_path: "components.cart.reviewOrder"
            }
        },
        computed: {
            show() {

                const allowedRoutes = [
                    "home",
                    "products",
                    "products.product",
                    "catalog",
                    "tunnel.service"
                ];

                return 0 < this.$store.getters["user/cart/items"].length && -1 !== allowedRoutes.indexOf(this.$route.name);
            }
        }
    }
</script>