export default {
    title: "Items Not Delivered",
    header: "@:views.survey.deliveryfaq.title",
    message: "You have indicated that you have not received your item despite the courier marking it as delivered.",
    cta: "Please follow these steps to help us troubleshoot the issue:",
    items: {
        1: {
            message: "Click on the tracking link:"
        },
        2: {
            message: "Review the delivery status. Check for:",
            items: {
                1: "Who signed for the package",
                2: "If was returned to sender",
                3: "If the parcel is waiting for pickup"
            }
        },
        3: {
            message: "Check for a delivery notice indicating where the item may have been left."
        },
        4: {
            message: "Check with neighbours, relatives, and friends who may have taken the parcel."
        },
        5: {
            message: "Check common places where couriers leave items in your absence."
        }
    },
    question: "Has this helped to resolve your delivery query? Do you now have the item or know who has it?",
    buttons: {
        yes: "Yes",
        no: "No",
        saving: "Saving"
    }
}