<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #message>{{t('message')}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import mixinView from "@/mixins/view";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsAddressVerified",
        components: {PageMessage},
        mixins: [mixinView],
        data() {

            return {
                t_path: "views.address.verified",
            }
        },
    }
</script>