<template>
    <panel class="d-flex">
        <div class="products-list-product">

            <div class="d-none d-md-block">
                <products-product-thumbnails v-if="0 < value.pictures.length"
                                             :route="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}"
                                             :value="value.pictures"></products-product-thumbnails>
            </div>
            <div class="d-md-none" v-if="mainPicture">
                <router-link
                        :to="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}">
                    <img :src="mainPicture" :alt="name" class="image"/>
                </router-link>
            </div>

            <div class="title flex-grow-1">
                <router-link class="products-list-product-title-link"
                             :to="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}">
                    {{name}}
                </router-link>

                <div v-if="estimatedDeliveryDate" v-html="estimatedDeliveryDate" class="note pt-2 font-italic"></div>

                <div class="note" v-if="note" v-html="note"></div>

                <products-list-product-description :description="description"
                                                   :route="{name:'products.product', params: {grouping:grouping, grouping_id:groupingId,sku:value.sku}}"
                                                   :max-height="40"/>
            </div>

            <div class="text-center pt-3 pb-3">

                <button v-if="displayAdd" type="button" class="btn-cart btn btn-sm btn-primary" :disabled="disableAdd" @click="add">
                    {{t('buttons.cart.add')}}
                </button>
                <button v-if="displayRemove" type="button" class="btn-cart btn btn-sm btn-outline-primary"
                        @click="cart_remove(value)">
                    {{t('buttons.cart.remove')}}
                </button>

                <div class="cart-toast">
                    <div class="message" :class="{'display':added.display}">
                        {{t('added')}}
                    </div>
                </div>
            </div>

        </div>
    </panel>
</template>

<script>


    import cart from "@/mixins/cart";
    import mixinConstraints from "@/mixins/constraints";
    import mixinProducts from "@/mixins/products";

    import Panel from "../Panel";
    import ProductsProductThumbnails from "./product/ProductsProductThumbnails";
    import ProductsListProductDescription from "./ProductsListProductDescription";


    export default {
        name: "ProductsListProduct",
        components: {
            ProductsListProductDescription,
            Panel,
            ProductsProductThumbnails
        },
        mixins: [cart, mixinConstraints, mixinProducts],
        props: {
            value: {
                type: Object,
                required: true
            },
            grouping: {
                type: String,
                required: true,
            },
            groupingId: {
                type: [Number, String],
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.list.product",

                added: {
                    timeout: null,
                    display: false
                }
            }
        },
        computed: {
            displayAdd() {

                return 0 < this.constraints_filterAvailableProducts([this.value], true).length
                    || 1 < this.constraints_getProductTypeAvailableAmount(this.value)
                    || !this.$store.getters["user/cart/exists"](this.value);
            },
            disableAdd() {

                return 0 === this.constraints_filterAvailableProducts([this.value], true).length;
            },
            displayRemove() {

                return 1 === this.constraints_getProductTypeAvailableAmount(this.value)
                    && !this.displayAdd
                    && this.$store.getters["user/cart/exists"](this.value);
            },
            mainPicture() {

                if (0 === this.value.pictures.length) {

                    return null;
                }

                return this.value.pictures[0].medium;
            },
            name() {

                return this.products_translateProductName(this.value);
            },
            note() {

                return this.products_translateProductNote(this.value);
            },
            description() {

                return this.products_translateProductDescription(
                    this.value,
                    ["b", "i", "em", "strong", "br", "ul", "ol", "li"]
                );
            },
            estimatedDeliveryDate() {

                return this.products_translateProductEstimatedShipDate(this.value);
            }
        },
        methods: {
            add() {

                clearTimeout(this.added.timeout);
                this.added.display = true
                this.added.timeout = setTimeout(() => this.added.display = false, 1000);

                this.cart_add(this.value);
            }
        }
    }
</script>