export default {
    namespaced: true,
    state: {
        item: null
    },
    getters: {
        item: state => state.item
    },
    mutations: {
        setItem: (state, item) => state.item = item
    },
    actions: {
        setItem: (context, item) => context.commit("setItem", item)
    }
}