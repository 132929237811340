<template>
    <div class="modal-quiz-profile">
        <div class="modals-modal-body">

            <quiz-page v-if="page"
                       v-model="model"
                       :quiz="quiz"
                       :quiz-page="page"
                       :components="components"
                       :disabled="processing"
                       :is-last-page="isLastPage"
                       :display-back-button="displayBackButton"
                       :next-button-text="nextButtonText"
                       :back-button-text="backButtonText"
                       @back="back"
                       @next="next"></quiz-page>

        </div>
    </div>
</template>

<script>

    import quiz from "@/quiz/profile";

    import mixinQuizPage from "@/mixins/quiz/page.mjs";
    import mixinQuizComponents from "@/mixins/quiz/components.mjs";
    import mixinQuizProfile from "@/mixins/quiz/profile.mjs";
    import mixinQuizCss from "@/mixins/quiz/css.mjs";
    import mixinQuizPayload from "@/mixins/quiz/payload.mjs";

    import QuizPage from "@/components/quiz/QuizPage";
    import {INITIAL_PROFILE_VERSION} from "@/store/user/profile";

    export default {
        name: "ModalQuizProfile",
        mixins: [mixinQuizPage, mixinQuizComponents, mixinQuizProfile, mixinQuizCss, mixinQuizPayload],
        components: {
            QuizPage,
        },
        data() {

            return {
                t_path: "modal.quizProfile",
                processing: false,
                page: null,
                model: {},
                quiz,
                queue: []
            };
        },
        computed: {
            isLastPage() {

                return this.quiz_page_isLastPage(
                    quiz,
                    this.page,
                    true === this.$store.getters["user/profile/profile_submitted"],
                    this.$store.getters["user/profile/profile_submitted_version"],
                    this.model.allowedProfileVersion
                );
            },
            displayBackButton() {

                const pages = this.quiz_page_getQueue(
                    quiz,
                    true === this.$store.getters["user/profile/profile_submitted"],
                    this.$store.getters["user/profile/profile_submitted_version"],
                    this.model.allowedProfileVersion
                );

                return this.page && 0 < pages.findIndex(page => page.id == this.page.id);
            },
            nextButtonText() {

                return this.isLastPage ? this.t("buttons.submit") : this.t("buttons.next");
            },
            backButtonText() {

                return this.t("buttons.back");
            },
            components() {

                if (!this.page || !this.page.components) {

                    // Return empty list if page does not have components in config
                    return [];
                }

                return this
                    .quiz_getComponentsToDisplay(quiz, this.page.components)
                    .map(component => this.quiz_components_process(quiz, component.name));
            },
        },
        methods: {
            next() {

                if (this.isLastPage) {

                    return this.save();
                }

                this.page = this.quiz_page_getNextPage(
                    quiz,
                    this.page,
                    true === this.$store.getters["user/profile/profile_submitted"],
                    this.$store.getters["user/profile/profile_submitted_version"],
                    this.model.allowedProfileVersion
                );
            },
            back() {

                this.page = this.quiz_page_getPrevPage(
                    quiz,
                    this.page,
                    true === this.$store.getters["user/profile/profile_submitted"],
                    this.$store.getters["user/profile/profile_submitted_version"],
                    this.model.allowedProfileVersion
                );
            },
            save() {

                this.processing = true;

                // Prepare request
                const request = {
                    ...this.quiz_payload_get(quiz, this.model),
                    profile_submitted: true
                };

                // Save profile
                this.$store.dispatch("user/profile/post", request)
                    .then(() => this.$store.dispatch("user/fetch").then(() => this.$emit("save")))
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            }
        },
        watch: {
            components: {
                immediate: true,
                deep: true,
                handler(components) {

                    components.forEach(component => {

                        const pageComponent = this.page.components.find(pageComponent => pageComponent.name == component.name);

                        if (pageComponent && pageComponent.value && !this.model[component.name]) {

                            this.model[component.name] = pageComponent.value;
                        }
                    });
                }
            }
        },
        mounted() {

            Promise.all([this.$store.dispatch("user/quiz/profile/fetch")]).then(() => {

                // Get quiz
                this.model = Object.assign(
                    this.$store.getters["user/profile/profile"],
                    this.$store.getters["user/quiz/profile/model"]
                );

                // Figure out allowed profile version based on config and selected country
                this.model.allowedProfileVersion = this.quiz_profile_getAllowedVersion(
                    this.model.country,
                    this.$store.getters["user/profile/profileVersions"],
                    INITIAL_PROFILE_VERSION
                );

                this.queue = this.quiz_page_getQueue(
                    quiz,
                    true === this.$store.getters["user/profile/profile_submitted"],
                    this.$store.getters["user/profile/profile_submitted_version"],
                    this.model.allowedProfileVersion
                );

                if (0 === this.queue.length) {

                    console.error("QUEUE is empty");

                    return false;
                }

                this.page = this.queue[0];

                // Determine page to display
                if (!this.quiz_isPageShouldBeDisplayed(quiz, this.page)) {

                    this.page = this.quiz_page_getNextPage(
                        quiz,
                        this.page,
                        true === this.$store.getters["user/profile/profile_submitted"],
                        this.$store.getters["user/profile/profile_submitted_version"],
                        this.model.allowedProfileVersion
                    );

                    if (null === this.page) {

                        if (this.model.allowedProfileVersion != this.$store.getters["user/profile/profile_submitted_version"]) {

                            // User is allowed to have higher equipment version, but does not have access to newer equipment version pages
                            this.save();

                            return;
                        }

                        throw new Error("Current page is not allowed, but there is no next page");
                    }
                }

                this.quiz_components_processOKTA();

                this.init = true;

            });
        }
    }
</script>