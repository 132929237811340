<template>
    <div class="footer">
        <div class="border"></div>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="copyright">
                            {{$t('footer.copyright', {year: year})}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {DateTime} from "luxon"

    export default {
        name: "Footer",
        data() {

            return {
                year: DateTime.local().year
            }
        }
    }
</script>