<template>
    <span class="address-viewer">

        <!-- Address -->
        <span class="address">
            <!-- Address details-->
            <span v-for="item in items" :key="item" class="item pre-white-space">{{item}}</span>

        </span>


        <span class="control">

            <span v-if="showDeleteButton" class="d-block pb-3">
                <button type="button" class="btn btn-link btn-control"
                        :disabled="disabled||processing" @click="deleteEmail(value._id)">
                    <span v-if="deleting">
                        <span class="animate-spin icon-spinner"></span>{{t('buttons.delete.processing')}}
                    </span>
                    <span v-else>
                        {{t('buttons.delete.cta')}}
                    </span>
                </button>
            </span>

            <span v-if="showTfaButton&&value.tfaNeedAddressVerification" class="d-block">

                <span class="title">{{t('unverified')}}</span>

                <button type="button" class="btn btn-link btn-control"
                        :disabled="disabled||processing"
                        @click="sendVerificationEmail(value._id)">
                    <loading v-if="verification"></loading>
                    <span v-else>{{t('verificationLink')}}</span>
                </button>

            </span>
        </span>

    </span>
</template>

<script>
    import Loading from "../Loading";

    export default {
        name: "AddressViewer",
        components: {
            Loading
        },
        props: {
            value: {
                type: Object,
                required: true
            },
            disabled: Boolean,
            showDeleteButton: Boolean,
            showTfaButton: Boolean
        },
        data() {

            return {
                t_path: "components.address.viewer",

                deleting: false,
                verification: false
            }
        },
        computed: {
            items() {

                return [
                    this.value.name,
                    this.value.identifier,
                    this.value.company,
                    this.value.firstStreet,
                    this.value.secondStreet,
                    this.value.thirdStreet,
                    this.location,
                    this.value.postalCode,
                    this.value.country,
                    this.value.phoneNumber,
                    this.value.deliveryInstructions
                ].filter(i => !!i);
            },
            location() {

                const result = [
                    this.value.city,
                    this.value.state,
                    this.value.province,
                    this.value.county,
                    this.value.region
                ];

                return result.filter(i => !!i).join(", ");
            }


        },
        methods: {
            deleteEmail(addressId) {

                const addressDeleted = this.t('addressDeleted');
                const deleteAddressConfirmation = this.t('deleteAddressConfirmation');

                this.$modal.confirm(deleteAddressConfirmation).then(() => {

                    this.processing = true;
                    this.deleting = true;

                    return this.$store.dispatch("user/address/delete", addressId)
                        .then(() => this.$toast.success(addressDeleted))
                        .catch(error => this.base_error(error))
                        .finally(() => {

                            this.processing = false;
                            this.deleting = false;
                        });
                });
            },
            sendVerificationEmail: function (addressId) {

                this.processing = true;
                this.verification = true;

                return this.$store.dispatch("user/address/confirm", addressId)
                    .then(() => this.$toast.success(this.t('verificationEmailSent')))
                    .catch(error => this.base_error(error))
                    .finally(() => {

                        this.processing = false;
                        this.verification = false;
                    });
            }
        }
    }
</script>