import {createI18n} from 'vue-i18n'
import enUS from './locales/en-US'
import ptBR from './locales/pt-BR'
import fr from './locales/fr'
import es from './locales/es'
import pt from './locales/pt'
import datetimeFormats from "./datetimeFormats"

export function setupI18n(locale = "en-US") {

    const i18n = createI18n({
        locale,
        warnHtmlInMessage: "off",
        fallbackLocale: {
            "en-US": ["en-US"],
            "pt-BR": ["pt-BR", "en-US"],
            "pt": ["pt","en-US"],
            "fr": ["fr", "en-US"],
            "es": ["es", "en-US"]
        },
        messages: {
            "en-US": enUS,
            "pt-BR": ptBR,
            "pt": pt,
            "fr": fr,
            "es": es
        },
        datetimeFormats,
        silentTranslationWarn: true
    });

    setI18nLanguage(i18n, locale);

    return i18n
}

export function setI18nLanguage(i18n, locale) {

    i18n.global.locale = locale;
}