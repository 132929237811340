<template>
    <div v-if="showBanners" class="banners">
        <div v-for="banner in banners" :key="banner.id" class="banner">
            {{banner.title}}
            <router-link :to="banner.to" class="link">{{banner.cta}}</router-link>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"

    import {types} from "@/store/user/surveys";

    export default {
        name: "Banners",
        data() {

            return {
                t_path: "components.banners"
            };
        },
        computed: {
            ...mapGetters({
                items: "user/surveys/items"
            }),
            showBanners() {

                return 0 < this.banners.length && -1 === ["auth.sso"].indexOf(this.$route.name);
            },
            banners() {

                return this.items
                    .filter(item => {

                        if (this.$route.name === "approvals" && item.survey_type === types.pending_approvals) {

                            return false;
                        }

                        return true;
                    })
                    .map(item => {

                        const title = this.te(item.data.banner_title)
                            ? this.t(item.data.banner_title)
                            : item.data.banner_title;
                        const id = item._id ? item._id : Math.random();

                        if (item.survey_type === types.pending_approvals) {

                            return {
                                id,
                                title,
                                to: {name: "approvals"},
                                cta: this.t("approve")
                            }
                        }

                        return {
                            id,
                            title,
                            to: {name: "surveys.view", params: {survey_id: item._id}},
                            cta: this.t("open")
                        };
                    });
            }
        },
    }
</script>