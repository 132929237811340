import {createStore} from 'vuex'
import user from "./user";
import breadcrumbs from "./breadcrumbs";
import db from "./db";
import widget from "./widget";
import i18n from "./i18n";
import modals from "./modals";
import tree from "./tree";

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        user,
        breadcrumbs,
        db,
        widget,
        i18n,
        modals,
        tree
    }
})
