export default {
    title: "Approval",
    error: "An error occurred",
    saved: "Approvals submitted",
    noApprovals: "Nothing to approve",
    review: "Please review and edit cost center if incorrect",
    priorApprovals: "Prior&nbsp;approvals",
    noOrders: "Nothing to approve",
    noPriorApprovals: "No prior approvals",
    approved: "Approved",
    notApproved: "Not Approved",
    confirm: "There are still orders with pending approvals. Do you wish to continue with your current changes?",

    orders: {
        name: "Name",
        date: "Order Date",
        details: "Order Details",
        cost: "Cost",
        costCenter: "Cost Center",
        approval: "Approval"
    },

    model:{
        reason: {
            placeholder: "Reason"
        },
        approve: {
            yes: {
                label: "Yes"
            },
            no: {
                label: "No"
            }
        }
    },

    buttons: {
        submit: {
            text: "Submit",
            processing: "Processing"
        }
    }
}