<template>
    <div class="address-form">

        <form @submit.prevent="base_submit('model', 'submit', null)">

            <div class="title">{{t('title')}}</div>

            <div v-if="info" class="info pb-3" v-html="info"></div>

            <form-select :model-value="model.country"
                         select-class="form-control form-control-sm"
                         :label="t('model.country.label')"
                         :options="countries"
                         option-value="alpha3"
                         :disabled="true"></form-select>

            <div v-for="(field, index) in address_form_getAddressFields(config)" :key="field.field">

                <form-textbox v-if="fieldTypes.text == field.type"
                              textbox-class="form-control form-control-sm"
                              v-model="model[field.field]"
                              :validator="v$.model[field.field]"
                              :label="address_form_getAddressFieldTranslation(field, 'label', model.country)"
                              :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', model.country)"
                              :focus="0==index"
                              :errors="errors[field.field]"
                              :mask="field.mask"
                              :maxlength="field.maxlength"
                              :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                              :disabled="processing"
                ></form-textbox>

                <form-textarea v-if="fieldTypes.textarea == field.type"
                               textarea-class="form-control form-control-sm"
                               v-model="model[field.field]"
                               :validator="v$.model[field.field]"
                               :label="address_form_getAddressFieldTranslation(field, 'label', model.country)"
                               :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', model.country)"
                               :focus="0==index"
                               :errors="errors[field.field]"
                               :maxlength="field.maxlength"
                               :rows="field.rows"
                               :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                               :disabled="processing"
                ></form-textarea>

                <form-select v-if="fieldTypes.dropdown == field.type"
                             select-class="form-control form-control-sm"
                             v-model="model[field.field]"
                             :validator="v$.model[field.field]"
                             :label="address_form_getAddressFieldTranslation(field, 'label', model.country)"
                             :options="address_form_getAddressFieldOptions(field)"
                             :focus="0==index"
                             :errors="errors[field.field]"
                             :disabled="processing"></form-select>

            </div>

            <div class="form-group small" v-if="displayDisclaimer">{{t('disclaimer')}}</div>

            <div class="form-group">
                <div class="row">
                    <div class="col-6">
                        <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                            <spinner v-if="saving"></spinner>
                            {{saving ? t('buttons.submit.saving') : t('buttons.submit.text')}}
                        </button>
                    </div>
                    <div v-if="!hideCancel" class="col-6 text-right">
                        <button type="button" class="btn btn-sm btn-outline-primary"
                                :disabled="processing"
                                @click="$emit('cancel')">
                            {{t('buttons.cancel.text')}}
                        </button>
                    </div>
                </div>
            </div>

        </form>

    </div>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {mapGetters} from "vuex"
    import address_info_config from "./form/address_info_config";

    import FormTextbox from "@/components/form/FormTextbox";
    import FormSelect from "@/components/form/FormSelect";
    import FormTextarea from "@/components/form/FormTextarea";
    import {sanitize} from "@/helpers/html";
    import mixinAddressForm from "@/mixins/address/form";

    const fieldTypes = {
        text: "text",
        textarea: "textarea",
        dropdown: "dropdown"
    };

    const model = {};

    export default {
        name: "AddressForm",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixinAddressForm],
        components: {
            FormTextarea,
            FormTextbox,
            FormSelect
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            value: {
                type: Object
            },
            saving: {
                type: Boolean,
                default: false
            },
            hideCancel: Boolean
        },
        data() {

            const fields = this.address_form_getAddressFields(this.$store.getters["user/address/config"]);
            const country = this.$store.getters["user/profile/country"];

            return {
                t_path: "components.address.form",

                processing: false,
                model: {
                    country,
                    ...fields.reduce((model, field) => {

                        if ("name" === field.field) {

                            model[field.field] = this.$store.getters["user/profile/name"]
                                .split(" ")
                                .map(string => string.charAt(0).toUpperCase() + string.slice(1))
                                .join(" ");
                        }

                        return model
                    }, {})
                },

                fieldTypes,
            }
        },
        computed: {
            ...mapGetters({
                countries: "db/countries/items",
                config: "user/address/config"
            }),
            displayDisclaimer() {

                return 0 < this.$store.getters["user/address/items"].length;
            },
            info() {

                if (this.$store.getters["user/address/header"]) {

                    return sanitize(this.$store.getters["user/address/header"]);
                }

                if ("undefined" !== typeof address_info_config[this.model.country]) {

                    return address_info_config[this.model.country];
                }

                return null;
            },
            errors() {

                return this.address_form_getErrorMessages(
                    this.address_form_getAddressFields(this.config),
                    this.model.country
                );
            }
        },
        validations() {

            return this.address_form_createValidationRules(
                this.address_form_getAddressFields(this.config)
            );
        },
        methods: {
            submit() {

                this.processing = true;

                // Sometimes this.$t does not exist in success handler
                const message = this.t("addressAdded");

                this.$store
                    .dispatch("user/address/post", {
                        ...this.model,
                        redirectUrl: `${window.location.origin}${this.$router.resolve({name: "address.verified"}).fullPath}`,
                        country: this.$store.getters["user/profile/country"]
                    })
                    .then(() => {
                        this.$emit("saved");
                        this.$toast.success(message);
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            }
        }
    }
</script>