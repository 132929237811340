<template>
    <nav v-if="0 < breadcrumbs.length" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li v-for="(page, pageIndex) in breadcrumbs" :key="page.text" class="breadcrumb-item"
                :class="[{active:!page.to&&!page.dropdown,dropdown:page.dropdown,'d-none d-md-block':1 < pageIndex}, page.class]">
                <router-link v-if="page.to" :to="page.to">{{page.text}}</router-link>
                <span v-else-if="!page.dropdown">{{page.text}}</span>
                <a v-else-if="page.dropdown" href="#" role="button"
                   v-dropdown="{variable: dropdownRefs, key: pageIndex}">
                    <span>{{page.text}}</span>
                    <span class="ml-1 icon-down-dir"></span>
                </a>

                <div v-if="page.dropdown" class="dropdown-menu " :ref="element => setDropdownRef(pageIndex, element)">
                    <router-link v-for="(dropdownPage, dropdownPageIndex) in page.dropdown"
                                 class="dropdown-item"
                                 :class="{active:dropdownPage.active}"
                                 exact-active-class="active"
                                 :key="dropdownPageIndex"
                                 :to="dropdownPage.to">
                        {{dropdownPage.text}}
                    </router-link>
                </div>
            </li>
        </ol>
    </nav>
</template>

<script>

    import {mapGetters} from 'vuex'

    export default {
        name: "Breadcrumbs",
        data() {

            return {
                dropdownRefs: {}
            }
        },
        computed: {
            ...mapGetters({
                breadcrumbs: "breadcrumbs/items"
            })
        },
        methods: {
            setDropdownRef(index, element) {

                this.dropdownRefs[index] = element;
            }
        },
        beforeUpdate() {

            this.dropdownRefs = {}
        },
    }
</script>
