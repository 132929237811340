const date = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
};

const monthDate = {
    month: "short",
    day: "numeric"
};

const time = {
    hour: "numeric",
    minute: "numeric",
    hour12: true
};

export default {
    date,
    monthDate,
    datetime: {
        ...date,
        ...time,
    }
}