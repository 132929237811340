export default {
    methods: {
        constraints_updateConstraintsWithCart(constraints) {

            const cart = this.$store.getters["user/cart/items"];

            Object.keys(constraints).forEach(type => {

                constraints[type] -= cart.reduce((total, cartItem) => {

                    if (-1 !== cartItem.item.product_type.indexOf(type)) {

                        total += cartItem.amount;
                    }

                    return total;
                }, 0);

            });

            return constraints;
        },
        constraints_getProductTypeAvailableAmount(product) {

            const constraints = this.$store.getters["user/constraints/type"];

            return constraints[product.product_type] ?? 0;
        },
        constraints_getProductAvailableAmount(product) {

            const constraints = this.$store.getters["user/constraints/type"];
            const cart = this.$store.getters["user/cart/items"];

            // Handle cart items
            Object.keys(constraints).forEach(type => {

                constraints[type] -= cart.reduce((total, cartItem) => {

                    if (cartItem.item.sku != product.sku) {

                        if (-1 !== cartItem.item.product_type.indexOf(type)) {

                            total += cartItem.amount;
                        }
                    }


                    return total;
                }, 0);
            });


            return product.product_type.reduce((result, type) => {

                if (0 == result || result > constraints[type]) {

                    result = constraints[type];
                }

                return result;
            }, 0);

        },
        constraints_hasUserAvailableProducts(handleCart) {

            const constraints = handleCart
                ? this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"])
                : this.$store.getters["user/constraints/type"];

            return null !== constraints && 0 <= Object.keys(constraints).reduce((result, type) => {

                return result + constraints[type];
            }, 0);

        },
        constraints_filterAvailableProducts(products, handleCart) {

            const constraints = handleCart
                ? this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"])
                : this.$store.getters["user/constraints/type"];

            return products.filter(item => {

                for (let i = 0; i < item.product_type.length; i++) {

                    if ("undefined" !== typeof constraints[item.product_type[i]] && 0 >= constraints[item.product_type[i]]) {

                        return false;
                    }
                }

                return true;
            })
        },
        constraints_hasAnyAvailableProducts() {

            const constraints = this.constraints_updateConstraintsWithCart(this.$store.getters["user/constraints/type"]);

            return 0 < Object.keys(constraints).reduce((result, type) => result + constraints[type], 0);
        }
    }
}