<template>
    <form @submit.prevent="submit">

        <div class="form-group label-large" v-for="(choice, choiceIndex) in items" :key="choiceIndex">

            <div class="d-flex pb-3" v-for="(item, itemIndex) in choice.items" :key="choiceIndex + '_' + item.sku">
                <div class="pr-3">
                    <div class="input-group-text">
                        <input type="radio" class="radio-large"
                               :name="'choice_' + choiceIndex"
                               :id="choiceIndex + '_' + item.sku"
                               :disabled="disabled"
                               :checked="model[choiceIndex]==choice.items[itemIndex].sku"
                               :value="item.sku"
                               @input="model[choiceIndex]=choice.items[itemIndex].sku"/>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <label :for="choiceIndex + '_' + item.sku">
                        <span class="d-block font-weight-bolder pb-1">{{item.name}}</span>
                        <span class="d-block small" v-html="item.description"></span>
                    </label>
                </div>
            </div>
        </div>

        <div class="form-group text-center">
            <button type="submit" class="btn btn-primary" :disabled="disabled">
                <spinner v-if="disabled"></spinner>
                {{disabled ? t('processing') : t('cta')}}
            </button>
        </div>
    </form>
</template>

<script>

    import mixinProducts from "@/mixins/products";

    import Spinner from "@/components/Spinner";

    export default {
        name: "ViewsSurveysViewProductChoice",
        mixins: [mixinProducts],
        components: {
            Spinner
        },
        props: {
            choices: {
                type: Array,
                required: true
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "views.surveys.view.productChoice",
                model: []
            };
        },
        computed: {
            items() {

                return this.choices.map((choice, choiceIndex) => {

                    return {
                        id: choiceIndex,
                        items: choice.items.map(item => {

                            return {
                                name: this.products_translateProductName(item),
                                sku: item.sku,
                                description: this.products_translateProductDescription(item)
                            }
                        })
                    }
                });
            }
        },
        methods: {
            submit() {

                this.$emit("process", this.model.map(sku => [sku]));
            }
        },
        mounted() {

            this.choices.forEach(choice => {

                if ("undefined" !== typeof choice.initial_index && "undefined" !== typeof choice.items[choice.initial_index]) {

                    this.model.push(choice.items[choice.initial_index].sku);
                } else if (0 < choice.items.length) {

                    this.model.push(choice.items[0].sku);
                }
            });
        }
    }
</script>