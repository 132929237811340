export default {
    status: {
        delivery_not_confirmed: "Shipped",
        acknowledged: "Ordered",
        created: "Created",
        sent: "Ordered",
        confirmed: "Ordered",
        not_confirmed: "Not Ordered",
        delivered: "Delivered",
        delivery_email: "Shipped",
        shipped: "Shipped",
        delayed: "Delayed",
        canceled: "Canceled",
        preparing: "Preparing",
        processing: "Processing",
        processed: "Processed",
        submitted: "Ordered",
        delivery_received: "Delivered",
        delivery_not_received: "Shipped",
        returned: "Returned",
        refurbished: "Refurbished",
        returned_to_office: "Returned to office",
        disposed: "Disposed",
        lost_shipment: "Lost Shipment",
        return_pickup_scheduled: "Pickup Scheduled",
        return_pickup_completed: "Pickup Completed",
        return_pickup_excluded: "Pickup Excluded",
        return_pickup_submitted: "Pickup Submitted",
        return_pickup_finalized: "Pickup Finalized",
        return_to_employer: "Return to Employer",
        damaged: "Damaged",
        shipment_exception: "Shipped",
        pending_approval: "Pending",
        approved: "Approved",
        not_approved: "Not Approved"
    }
}