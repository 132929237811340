<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.login')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import {USER_TOKEN_STORAGE_KEY} from "@/api/storage";
    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsAuthLogout",
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.auth.logout"
            };
        },
        mounted() {

            // Remove token from selected storage
            this.$storage.remove(USER_TOKEN_STORAGE_KEY);

            // Logout user
            this.$store.dispatch("user/logout");
        }
    }
</script>